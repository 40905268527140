import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  public turnirId = 0;
  public host: string;

  constructor(private route: ActivatedRoute) {
    this.host = 'http://locahost:4200';

    this.route.paramMap.subscribe(paramMap => {
      if (paramMap != null) {
        if (isNaN(Number(paramMap.get('turnirid')))) {
          this.turnirId = 0;
        } else {
          this.turnirId = Number(paramMap.get('turnirid'));
        }
      }
    })
  }

  ngOnInit(): void {
  }

}

import { APP_INITIALIZER, Provider } from '@angular/core';
import { Observable, of} from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ConfigService } from '../services/config.service';
import { LoginService } from '../services/login.service';

export const appInitializerProvider: Provider = {
  provide: APP_INITIALIZER,
  deps: [ConfigService, LoginService],
  useFactory(configService: ConfigService, loginService: LoginService): () => Observable<void> {
    
    return (): Observable<any> => {
      return configService.loadConfig().pipe(
        switchMap(() => loginService.testSession()),
        catchError(() => {
          return of(null);
        }),
      );
    };
  },
  multi: true,
};
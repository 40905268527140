import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { MessageService } from 'primeng/api';
import { DatesService } from 'src/app/services/dates.service';
import { LoginService } from 'src/app/services/login.service';
import { NetworkService } from 'src/app/services/network.service';

@Component({
  selector: 'app-turnir-detail',
  templateUrl: './turnir-detail.component.html',
  styleUrls: ['./turnir-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TurnirDetailComponent implements OnInit {
  public Editor = ClassicEditor;
  errors: String[] = [];
  sportOptions: any[] = [];
  turnirTypeOptions: any[] = [];
  organizationOptions: any[] = [];
  sexOptions: any[] = [
    { id: 'm', name: 'Мужской' },
    { id: 'f', name: 'Женский' },
  ];

  row: any = {
    is_published: false,
    options: {
      weights: [],
      registration: {},
      files: {},
      decor: {},
      partner: {}
    },
  };

  constructor(
    private networkService: NetworkService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private router: Router,
    public loginService: LoginService,
    private datesService: DatesService
  ) {}

  ngOnInit(): void {
    this.networkService.getSportList({}).subscribe(result => {
      this.sportOptions = result.list;
    });

    this.networkService.getTurnirTypeList({}).subscribe(result => {
      this.turnirTypeOptions = result.list;
    });

    this.networkService.getOrganizationList({ fullList: true }).subscribe(result => {
      this.organizationOptions = result.list;
      this.organizationOptions.map(o => {
        o.name = o.id + ' - ' + o.name
        return o;
      })
      this.cdr.markForCheck();
    });

    this.route.paramMap.subscribe(paramMap => {
      const id = paramMap.get('turnirid');

      if (id === null || !+id) {
        return;
      }

      this.networkService.getTurnir(+id).subscribe(result => {
        this.row = result;
        this.row.options = this.row.options || {};
        this.cdr.markForCheck();
      });
    });
  }

  onSave() {
    this.errors = [];

    if (this.row.is_published) {
      const errs = this.getFormErrors();
      if (errs && errs.length > 0) {
        this.errors = errs;
        this.cdr.markForCheck();
        return;
      }
    }

    this.networkService.saveTurnir({ ...this.row }).subscribe(resp => {
      if (resp.result) {
        this.messageService.add({ key: 'main', severity: 'success', summary: 'Сохранено', detail: 'Изменения успешно сохранены' });
        if (!this.row.id) {
          this.router.navigate(['/turnirs', resp.result]);
        }
      } else if (resp.error) {
        this.messageService.add({ key: 'main', severity: 'error', summary: 'Ошибка', detail: resp.error });
      }
      this.cdr.markForCheck();
    }, 
    error => {
      this.messageService.add({ key: 'main', severity: 'error', summary: 'Ошибка', 
        detail: (error.error && error.error.message) ? error.error.message : '' });
    });
  }

  onFileSelected(event: Event) {
    this.cdr.markForCheck();
  }

  onDeleteAge(rowIndex: number) {
    if (!confirm('Удалить категорию?')) {
      return;
    }
    this.row.options.weights.splice(rowIndex, 1);
  }

  onDownloadAndConvertClick() {
    this.networkService.downloadAndConvertToPdf(this.row.turnir_json_new.files_as_images, this.row.id)
    .subscribe(data => {
      this.row.polozhenie_file_name = data.result.name;
      this.cdr.markForCheck();
    });
  }

  onAddWeight() {
    if (!this.row.options.weights) {
      this.row.options.weights = [];
    }
    this.row.options.weights.push({ sex: 'm' });
    this.cdr.markForCheck();
  }

  private setFileName(filename: string, discriminator: string) {
    switch (discriminator) {
      case 'POLOZHENIE': this.row.polozhenie_file_name = filename; break;
      case 'POSTER': this.row.options.files.poster = filename; break;
      case 'REGLAMENT': this.row.options.files.reglament = filename; break;
      case 'PROMO': this.row.options.files.promo = filename; break;
    }
  }

  onUploadFileClick(fileControl: any, discriminator: string) {
    this.networkService.uploadTurnirFile(fileControl.files[0], this.row.id, discriminator)
    .subscribe(data => {
      this.setFileName(data.name, discriminator);
      this.cdr.markForCheck();
    });
  }

  onUploadTurnirFromRemote(file: any, discriminator: string) {
    this.networkService.uploadTurnirFromRemote(file.url, this.row.id)
    .subscribe(result => {
      this.setFileName(result.result.name, discriminator);
      this.cdr.markForCheck();
    });
  }

  getArbitararyErrors(): String[] {
    const errors: String[] = [];

    if (this.row.fullname && this.row.fullname && this.row.fullname.length > 300) {
      errors.push('Полное название турнира 300 символов максимум. Текущее значение: ' + this.row.fullname.length);
    }

    if (this.row.name && this.row.name && this.row.name.length > 40) {
      errors.push('Полное название турнира 40 символов максимум. Текущее значение: ' + this.row.name  .length);
    }

    return errors;
  }

  getFormErrors(): String[] {
    const errors: String[] = [];

    if (this.row.name == null || this.row.name === '') {
      errors.push('Название турнира должно быть заполнено');
    }

    if (!this.row.sport_id) {
      errors.push('Вид спорта должен быть задан');
    }

    if (!this.row.turnir_type_id) {
      errors.push('Тип турнира должен быть задан');
    }

    if (this.row.fullname == null || this.row.fullname === '') {
      errors.push('Полное название турнира должно быть заполнено');
    }

    if (!this.row.organizator_id) {
      errors.push('Организатор должен быть задан');
    }

    if (!this.row.place_organization_id) {
      errors.push('Место проведения должно быть задано');
    }

    if (!this.row.start_date) {
      errors.push('Дата начала должна быть задана');
    }

    if (!this.row.polozhenie_file_name && !this.row.options.files.reglament) {
      errors.push('Положение или регламент должны быть загружены');
    }

    if (this.row && this.row.options && this.row.options.weights) {
      for(let w of this.row.options.weights) {
        if (!w.weights || !w.years) {
          errors.push('Пустой год и/или вес');
          break;
        }
      }
    }

    if (this.row.start_date && this.row.end_date) {
      debugger
      if (this.getStartDate().getTime() >= this.getStopDate().getTime()) {
        errors.push('Дата начала больше или равна дате завершения');
      }
    }

    if (this.row.start_date && this.row.options && this.row.options.registration && this.row.options.registration.endDate) {
      if (this.getStartDate().getTime() < this.datesService.dateFromServerFormat(this.row.options.registration.endDate)) {
        errors.push('Дата начала меньше или даты завершения регистрации');
      }
    }

    

    return errors;
  }

  isFormValid() {
    this.errors = this.getArbitararyErrors();
    
    if (this.row.is_published) {
      this.errors.push(...this.getFormErrors());
    }
   
    return this.errors.length === 0;
  }

  onTurnirOrganizeClick() {
    this.router.navigate(['/turnirs', this.row.id, 'organize']);
  }

  getStopDate() {
    if (this.row.end_date) {
      if (typeof this.row.end_date === 'string') {
        return this.datesService.dateFromServerFormat(this.row.end_date);
      }
      return this.row.end_date;
    }
    return this.datesService.dateFromServerFormat('2222-01-01');
  }

  getStartDate() {
    if (this.row.start_date) {
      if (typeof this.row.start_date === 'string') {
        return this.datesService.dateFromServerFormat(this.row.start_date);
      }
      return this.row.start_date;
    }
    return this.datesService.dateFromServerFormat('1900-01-01');
  }

  onNormalizeWeight(el: any) {
    console.log(el.weights)
    
    let r = el.weights.trim();
    const regex = /[^0-9\+]/ig;
    r = r.replace(regex, ' ').trim();
    el.weights = r.replace(/\s\s+/g, ', ');
  }
}

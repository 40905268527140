<div class="protocols-container"
  *ngFor="let age of data.info.ages"
>
  <div *ngIf="mustShowAge(age)">
    <div *ngFor="let weight of age.weights">
      <div *ngIf="weight.system_id == 1 && weight.battles.length > 0">
        <round-robin-protocol
          [age]="age"
          [weight]="weight"
          [sitList]="sitList"
          [turnir]="data.info"
        ></round-robin-protocol>
        <div class="spacer"></div>
      </div>
      <div *ngIf="weight.system_id == 2">
        <olimpic-protocol
          [age]="age"
          [weight]="weight"
          [sitList]="sitList"
        ></olimpic-protocol>
        <div class="spacer"></div>
      </div>
    </div>
  </div>
</div>

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { SportsmenInTurnir } from 'src/app/common/models/Organization';
import { NetworkService } from 'src/app/services/network.service';

@Component({
  selector: 'app-weight-protocols',
  templateUrl: './weight-protocols.component.html',
  styleUrls: ['./weight-protocols.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WeightProtocolsComponent implements OnInit {
  protocolData:any = {};

  _turnirId: number = -1;
  @Input()
  set turnirId(id: number) {
    this._turnirId = id;
  }

  get turnirId() {
    return this._turnirId;
  }

  constructor(private networkService: NetworkService,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private cdr: ChangeDetectorRef) {
    this.route.paramMap.subscribe(paramMap => {
      if (paramMap != null) {
        if (isNaN(Number(paramMap.get('turnirid')))) {
          this.turnirId = 0;
        } else {
          this.turnirId = Number(paramMap.get('turnirid'));
        }
      }
    })
  }

  ngOnInit(): void {
    this.getProtocols();
  }

  getProtocols() {
    this.networkService.turnirOrganizer_getWeightningProtocols(this.turnirId)
    .subscribe(
      result => {
        this.protocolData = result.result;
        this.cdr.markForCheck();
      },
      error => {
        this.messageService.add({ key: 'main', severity: 'error', summary: 'Ошибка', 
              detail: error.status + ' ' + error.statusText});
      }
    );
  }

  getProtocolYears(p: any) {
    if (!p['minYear']) {
      return 'Неотсортированные'
    }
    
    if (p['minYear'] === p['maxYear']) {
      return p['minYear'];
    }
    return p['minYear'] + ' - ' + p['maxYear'];
  }

  getWeights(p: any) {
    if (p['weightMin'] === null || p['weightMin'] === undefined) {
      return 'Неотсортированные';
    }

    return p['weightName'] + '(' + p['weightMin'] + ' - ' + p['weightMax'] +')'
  }

  getProves(p: any) {
    if (p['proves'] === null || p['proves'] === undefined) {
      return '0';
    }

    return p['proves'];
  }

  getYear(sit: SportsmenInTurnir) {
    if (!sit) {
      return '----'
    }
    return new Date(sit.birthdate).getFullYear();
  }

}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { SportsmenInTurnir } from 'src/app/common/models/Organization';
import { Turnir, TurnirAge, TurnirAgeWeight } from 'src/app/common/models/Turnir';

@Component({
  selector: 'round-robin-protocol',
  templateUrl: './round-robin-protocol.component.html',
  styleUrls: ['./round-robin-protocol.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoundRobinProtocolComponent implements OnInit {
  @Input()
  age: TurnirAge = {} as TurnirAge;

  @Input()
  weight: TurnirAgeWeight = {} as TurnirAgeWeight;

  @Input()
  sitList: Array<SportsmenInTurnir> = [];

  @Input()
  turnir: Turnir = {} as Turnir;

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.cdr.markForCheck();
  }

  getLaps(weight: TurnirAgeWeight) {
    const laps: any = [];

    for(const b of weight['battles']) {
      laps[b.lap - 1] = laps[b.lap - 1] || { lap: b.lap, battles: []};
      laps[b.lap - 1].battles.push(b)
    }

    for(let i in laps) {
      laps[i]['battles'].sort((a: any, b: any) => a['id'] - b['id']);
    }

    return laps;
  }

  getSitById(sitId: number): SportsmenInTurnir | null {
    if (sitId == null) {
      return null;
    }
    
    if (this.sitList[sitId]) {
      return this.sitList[sitId];
    }

    throw new Error(`SportsmenInTurnir with id=${sitId} not found`);
  }

  getSitNameById(sitId: number): string {
    const sit = this.getSitById(sitId);

    if (!sit) {
      return '';
    }
    return (sit.lastname ? sit.lastname : '') + ' ' + (sit.firstname ? sit.firstname : '');
  }

}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { WeightSportmensDetailComponent } from './weight-sportmens-detail/weight-sportmens-detail.component';
import { SportsmenInTurnir } from 'src/app/common/models/Organization';
import { NetworkService } from 'src/app/services/network.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-weighing',
  templateUrl: './weighing.component.html',
  styleUrls: ['./weighing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WeighingComponent implements OnInit {
  ref: DynamicDialogRef | undefined;
  list: Array<SportsmenInTurnir> = [];
  _turnirId: number = -1;

  @Input()
  set turnirId(id: number) {
    this._turnirId = id;
  }

  get turnirId() {
    return this._turnirId;
  }


  constructor(public dialogService: DialogService,
    private cdr: ChangeDetectorRef,
    private networkService: NetworkService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private route: ActivatedRoute,
    private router: Router) {
      this.route.paramMap.subscribe(paramMap => {
        if (paramMap != null) {
          if (isNaN(Number(paramMap.get('turnirid')))) {
            this.turnirId = 0;
          } else {
            this.turnirId = Number(paramMap.get('turnirid'));
          }
        }
      })
    }

  ngOnInit(): void {
    this.loadSportsmensInTurnir();
  }

  show(): void {
    const sit = {} as SportsmenInTurnir;
    this._show(sit);
  }

  _show(sit: SportsmenInTurnir): void {
    this.ref = this.dialogService.open(WeightSportmensDetailComponent, 
      {
        header: 'Добавление спортсмена',
        dismissableMask: false,
        contentStyle: { overflow: 'auto' },
        
        data: {
          sit
        }
      });
    
      this.ref.onClose.subscribe((sit: SportsmenInTurnir) => {
        if (sit) {
            this.saveSportsmenInTurnir(sit);
            if (!sit.id) {
              this.list.push(sit);
            }
            this.cdr.markForCheck();
        }
    });
  }

  onRowSelect(sit: SportsmenInTurnir) {
    this._show(sit);
  }

  loadSportsmensInTurnir() {
    // this.list = [
    //   { id: 11, firstname : 'Иван', middlename: 'Иванович', lastname: 'Иванов', birthdate: '12-12-2023', weight: 45.6 } as SportsmenInTurnir,
    //   { id: 12, firstname : 'Петр', middlename: 'Петрович', lastname: 'Петров', birthdate: '1-12-2023', weight: 55.5 } as SportsmenInTurnir,
    //   { id: 13, firstname : 'Сидор', middlename: 'Сидровоич', lastname: 'Сидоров', birthdate: '12-1-2023', weight: 35.1 } as SportsmenInTurnir,
    //   { id: 14, firstname : 'Василий', middlename: 'Васильевич', lastname: 'Васильев', birthdate: '1-1-2023', weight: 35.6 } as SportsmenInTurnir,
    // ];
    this.networkService.loadSportsmenInTurnirList(this.turnirId)
    .subscribe(result => {
      if (result.error) {
        const err = result.error;
        let text = 'Error code: ' + err.code + (err.reason ? (' Text: ' + err.reason) : '');
        this.messageService.add({ key: 'main', severity: 'error', summary: 'Ошибка', detail:  text});
      } else {
        this.list = result.result.list;
        this.cdr.markForCheck();
      }
    });
  }

  saveSportsmenInTurnir(sit: SportsmenInTurnir) {
    if (!sit.turnir_id) {
      sit.turnir_id = this.turnirId;
    }
    this.networkService.saveSportsmenInTurnir(sit)
    .subscribe(result => {
      if (result.error) {
        const err = result.error;
        let text = 'Error code: ' + err.code + (err.reason ? (' Text: ' + err.reason) : '');
        this.messageService.add({ key: 'main', severity: 'error', summary: 'Ошибка', detail:  text});
        this._show(sit);
      } else {
        this.loadSportsmensInTurnir();
      }
      this.cdr.markForCheck();
    }, 
    error => {
      this._show(sit);
      this.messageService.add({ key: 'main', severity: 'error', summary: 'Ошибка', 
        detail:  error.error && error.error.message ? error.error.message : ''});
    });
  }

  onRowDelete(sit: SportsmenInTurnir) {
    this.confirmationService.confirm({
        message: `Удалить спортсмена: ${sit.lastname} ${sit.firstname} ?`,
        header: 'Подтверждение удаления',
        icon: 'pi pi-info-circle',
        accept: () => {
          this.networkService.deleteSportsmenInTurnir(sit)
          .subscribe(result => {
            if (result.error) {
              const err = result.error;
              let text = 'Error code: ' + err.code + (err.reason ? (' Text: ' + err.reason) : '');
              this.messageService.add({ key: 'main', severity: 'error', summary: 'Ошибка', detail:  text});
            } else {
              const index = this.list.indexOf(sit);
              if (index !== -1) {
                this.list.splice(index, 1);
              }
            }
            this.cdr.markForCheck();
          },
          error => {
            this.messageService.add({ key: 'main', severity: 'error', summary: 'Ошибка', 
              detail:  error.error && error.error.message ? error.error.message : ''});
          });
        }
    });
  }

  stopWeightning() {
    this.confirmationService.confirm({
        message: `Вы уверены что хотите завершить взвешивание спортсменов? 
          Вы сможете возобновить группирование позже, но группировки и протоколы схваток станут некорректными и будут переформированы.`,
        header: 'Завершение взвешивания',
        icon: 'pi pi-info-circle',
        acceptLabel: 'Да, завершить',
        rejectButtonStyleClass: 'p-button-text',
        rejectLabel: 'Нет',
        defaultFocus: 'reject',
        accept: () => {
          this.networkService.turnirOrganizer_stopWeightning(this.turnirId)
          .subscribe(
            result => {
              this.router.navigate(['/turnirs', this.turnirId, 'organize']);
            },
            error => {
              this.messageService.add({ key: 'main', severity: 'error', summary: 'Ошибка', 
                detail: error.status + ' ' + error.statusText});
              this.cdr.markForCheck();
            }
          );
        }
    });
  }

}

<div class="flex justify-content-center align-items-center h-screen">
  <div class="surface-card p-4 shadow-2 border-round w-full lg:w-5 md:w-6 xl:w-3 sm:w-12 ">
    <div class="text-center mb-5">
      <img src="https://ritm-sporta.ru/images/new_logo_2.svg" alt="Image" height="50" class="mb-3" />
      <div class="text-900 text-3xl font-medium mb-3">Турнир-мастер</div>
    </div>

    <div>
      <label for="email1" class="block text-900 font-medium mb-2">Email</label>
      <input id="email1" type="text" pInputText class="w-full mb-3" [(ngModel)]="login" />

      <label for="password1" class="block text-900 font-medium mb-2">Пароль</label>
      <input id="password1" type="password" pInputText class="w-full mb-3" [(ngModel)]="password" />

      <div class="flex align-items-center justify-content-between mb-6">
        <a class="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer">Забыли пароль? TODO: сделать</a>
      </div>
      <button pButton pRipple type="button" label="Войти" class="p-button-rounded w-full" (click)="onLoginClick()"></button>
    </div>
  </div>
</div>

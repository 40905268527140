import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { SportsmenInTurnir } from 'src/app/common/models/Organization';
import { Turnir, TurnirAge } from 'src/app/common/models/Turnir';
import { NetworkService } from 'src/app/services/network.service';

@Component({
  selector: 'app-protocols',
  templateUrl: './protocols.component.html',
  styleUrls: ['./protocols.component.scss']
})
export class ProtocolsComponent implements OnInit {
  _turnirId: number = -1;
  sitList: Array<SportsmenInTurnir> = [];

  set turnirId(id: number) {
    this._turnirId = id;
  }

  get turnirId() {
    return this._turnirId;
  }

  public data: any = [];

  constructor(private networkService: NetworkService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private messageService: MessageService) {
      this.route.paramMap.subscribe(paramMap => {
        if (paramMap != null) {
          if (isNaN(Number(paramMap.get('turnirid')))) {
            this.turnirId = 0;
          } else {
            this.turnirId = Number(paramMap.get('turnirid'));
          }
        }
      })
    }

  ngOnInit(): void {
    this.getData();
    this.getSITList();
  }

  getData() {
    this.networkService.turnirOrganizer_getProtocols(this.turnirId)
    .subscribe(result => {
      this.data = result.result;
      this.cdr.markForCheck();
    },
    error => {
      this.messageService.add({ key: 'main', severity: 'error', summary: 'Ошибка', detail: error.message });
    });
  }

  getSITList() {
    this.networkService.loadSportsmenInTurnirList(this.turnirId)
    .subscribe(
      result => {
        const l = [];

        for(const sit of result.result.list) {
          l[sit.id] = sit;
        }

        this.sitList = l;
        this.cdr.markForCheck();
      },
      error => {
        this.messageService.add({ key: 'main', severity: 'error', summary: 'Ошибка', detail: error.message });
      }
    )
  }

  

  mustShowAge(age: TurnirAge) {
    if (age.min_year === 0 && age.max_year === 0) {
      return false;
    }
    return true;
  }

}

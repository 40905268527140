import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

interface Config {
  url: string;
  host: string;
}

@Injectable({ providedIn: 'root' })
export class ConfigService {
  config: Config;
  private readonly _configPath = `assets/config/config.json?t=${Date.now()}`;

  constructor(private httpClient: HttpClient) {
    this.config = {
      url: '',
      host: ''
    };
  }

  loadConfig(): Observable<Config> {
    return this.httpClient.get<Config>(this._configPath).pipe(
      tap((config) => {
        this.config = config;
      }),
      catchError((error) => {
        console.error('Read config error:', error);
        return throwError(() => error);
      }),
    );
  }
}

    <div class="bg-white p-3 mt-4 surface-border border-1 border-round-md">
        <p-table [columns]="cols" [value]="list" selectionMode="single" dataKey="id" 
        (onRowSelect)="onRowSelectHandler($event)" sortMode="single">
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns" [pSortableColumn]="col.sortableField">
                {{ col.header }} <p-sortIcon *ngIf="col.sortableField" [field]="col.sortableField"></p-sortIcon>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns" let-index="rowIndex">
            <tr class="table-row">
              <td *ngFor="let col of columns;" [pSelectableRow]="rowData">
                <div [ngSwitch]="col.field">              
                  <div *ngSwitchCase="'name'">
                    {{ (rowData.lastname || '') + ' ' + 
                      (rowData.firstname || '') + ' ' +
                      (rowData.middlename || '') }}
                  </div>
                  <div *ngSwitchCase="'index'">
                    {{ index + 1 }}
                  </div>
    
                  <div *ngSwitchCase="'is_published'">
                    <div class="status-indicator" [ngClass]="rowData[col.field] ? 'active' : ''"></div>
                  </div>
                  <div *ngSwitchCase="'actions'">
                    <button
                      pButton
                      pRipple
                      type="button"
                      icon="pi pi-trash"
                      class="p-button-rounded p-button-danger p-button-text delete-button"
                      (click)="deleteItem(rowData)"
                    ></button>
                  </div>
                  <div *ngSwitchDefault>{{ rowData[col.field] }}</div>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>

<div>
  <div class="filters">
    <div>
      <p-checkbox
        name="hideEmptyGroups"
        [(ngModel)]="data.hideEmptyGroups"
        [binary]="true"
        (onChange)="onHideEmptyGroupsChanged()"
      ></p-checkbox>
      <label for="hideEmptyGroups">Не показывать пустые группы</label>
    </div>
  </div>

  <div *ngFor="let age of groupsFormattedData" class="year">
    <p-fieldset legend="{{ age['min_year'] }} - {{ age['max_year'] }}" [toggleable]="true">
        <div *ngFor="let wg of age['_']['weightGroups']">
          <div *ngIf="mustShowAgeWeight(wg)" class="weight">
          <p-panel>
            <ng-template pTemplate="header">
              <div class="main-group-title">
                <span>{{ wg.descr.name }} ({{ wg.descr.min_weight }} - {{ wg.descr.max_weight }})</span>
                <p-button
                  icon="pi pi-plus"
                  styleClass="p-button-rounded p-button-text p-button-sm"
                  (click)="onAddGroup(age, wg.descr, wg.groups.length + 1)"
                ></p-button>
              </div>
            </ng-template>
            
            <div>
              <div class="groups">
                <p-card *ngFor="
                      let g of wg['groups'];
                      let lastGroup = last;
                      let firstGroup = first;
                      let index = index
                    "
                    pDroppable="sportsmens"
                    (onDrop)="drop($event, g)">
                  <ng-template pTemplate="header">
                    <div class="group-title">
                      <div>Группа&nbsp;{{ g.num }}</div>

                      <p-dropdown
                          [options]="systems"
                          [(ngModel)]="g.system_id"
                          optionLabel="name"
                          optionValue="id"
                          class="w-full"
                        ></p-dropdown>

                      <p-button
                        icon="pi pi-trash"
                        styleClass="p-button-rounded p-button-text p-button-sm"
                        *ngIf="lastGroup && !firstGroup"
                        (click)="onDeleteGroup(age, g)"
                      ></p-button>
                    </div>
                  </ng-template>
                  <div class="m-0">
                    
                    <div *ngIf="mustShowGroup(g)">
                      <div class="error">{{ getGroupErrors(g) }}</div>
      
                      <div
                        *ngFor="let s of g['sportsmens']"
                        pDraggable="sportsmens"
                        (onDragStart)="dragStart($event, s, g)"
                        (onDragEnd)="dragEnd()"
                        class="p-2 border-round shadow-1"
                      >
                        {{ getSitDescription(s.sit_id) }}
                      </div>
                    </div>
                  </div>
                </p-card>
                
                <!-- <div
                  *ngFor="
                    let g of wg['groups'];
                    let lastGroup = last;
                    let firstGroup = first;
                    let index = index
                  "
                  pDroppable="sportsmens"
                  (onDrop)="drop($event, g)"
                >
                  <div style="padding-left: 40px;" *ngIf="mustShowGroup(g)">
                    <div [class.error]="groupHasError(g)">Группа {{ g.num }}</div>
    
                    <p-button
                      icon="pi pi-trash"
                      styleClass="p-button-rounded p-button-text p-button-sm"
                      *ngIf="lastGroup && !firstGroup"
                      (click)="onDeleteGroup(age, g)"
                    ></p-button>
                    <div>
                      <p-dropdown
                        [options]="systems"
                        [(ngModel)]="g.system_id"
                        optionLabel="name"
                        optionValue="id"
                        class="w-full"
                      ></p-dropdown>
                    </div>
    
                    <div
                      *ngFor="let s of g['sportsmens']"
                      pDraggable="sportsmens"
                      (onDragStart)="dragStart($event, s, g)"
                      (onDragEnd)="dragEnd()"
                      class="p-2 border-round shadow-1"
                    >
                      {{ getSitDescription(s.sit_id) }}
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </p-panel>
        </div>
      </div>
    </p-fieldset>
  </div>

  <div class="button-bar">
    <button
      pButton
      pRipple
      class="w-auto mr-2 p-button"
      label="Сохранить"
      icon="pi pi-check"
      iconPos="left"
      (click)="onSaveClick()"
      type="button"
    ></button>
    <button
      pButton
      pRipple
      class="w-auto mr-2 p-button-warning"
      label="Завершить группирование"
      icon="pi pi-check"
      iconPos="left"
      [disabled]="!canFinishGrouping()"
      (click)="onGroupingStopClick()"
      type="button"
    ></button>
  </div>
</div>

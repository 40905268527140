<div class="app-layout" *ngIf="!reportMode">
  <div class="load-overlay" *ngIf="loading"><p-progressSpinner></p-progressSpinner></div>
  <div class="app-sidebar" [ngClass]="sidebarWidth ? 'p-2' : ''" *ngIf="loggedIn" [style.width]="sidebarWidth + 'px'">
    <app-sidebar class="w-full"></app-sidebar>
  </div>
  <div class="app-main">
    <app-header *ngIf="loggedIn"></app-header>
    <div class="app-content" [class]="!loggedIn ? 'h-full' : 'p-3'">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
<p-toast position="bottom-right" key="main"></p-toast>
<p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>

<div class="report-layout" *ngIf="reportMode">
  <router-outlet></router-outlet>
</div>
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NetworkService } from 'src/app/services/network.service';

@Component({
  selector: 'app-registered-apps-common-list',
  templateUrl: './registered-apps-common-list.component.html',
  styleUrls: ['./registered-apps-common-list.component.scss']
})
export class RegisteredAppsCommonListComponent implements OnInit {
  _turnirId = 0;
  registeredList: Array<any> = [];
  unmatchedList: Array<any> = [];

  @Input()
  set turnirId(v: number) {
    this._turnirId = v;
    this.getAllSportsmensList();
  }

  get turnirId(): number {
    return this._turnirId;
  }

  constructor(private networkService:NetworkService,
    private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
  }

  getAllSportsmensList() {
    this.registeredList = [];
    this.unmatchedList = [];
    
    this.networkService.getAllRegisteredSportsmensForTurnir(this.turnirId)
    .subscribe((data:any) => {
      console.log('ffff', data)
      this.registeredList = data.result.registered;
      this.unmatchedList = data.result.unmatched;
      
      this.cdr.markForCheck();
    });
  }

}

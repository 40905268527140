import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public login: string = '';
  public password: string = '';

  disabled: boolean = true;

    value1: string = '';

    value2: string = '';

    value3: string = '';

    value4: string = '';

    value5: string = 'Disabled';

  constructor(private loginService: LoginService) { }

  ngOnInit(): void {
  }

  public onLoginClick() {
    this.loginService.login(this.login, this.password);
  }
}

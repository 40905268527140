import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NetworkService } from 'src/app/services/network.service';

@Component({
  selector: 'app-total-stat',
  templateUrl: './total-stat.component.html',
  styleUrls: ['./total-stat.component.scss']
})
export class TotalStatComponent implements OnInit {
  _turnirId = 0;

  data: any = {};

  @Input()
  set turnirId(v: number) {
    this._turnirId = v;
    this.loadData();
  }

  get turnirId(): number {
    return this._turnirId;
  }
  constructor(private networkService: NetworkService,
    private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
  }

  loadData() {
    this.networkService.getTurnirRegistrationStat(this.turnirId)
    .subscribe((data:any) => {
     this.data = data['result'];
     this.cd.markForCheck();
   });
 }

  getUnmatchedCount() {
    if (!this.data['unmatchedSportsmens']) {
      return 0;
    }
    return this.data['unmatchedSportsmens'].length;
  }

  getAgeSportsmensCount(age: any): number {
    let sum = 0;

    for(const w of age['weights']) {
      sum += w['sportsmens'].length;
    }

    return sum;
  }

  getYear(date: any) {
    return (new Date(date)).getFullYear(); 
  }

}

<div>
    <div class="center">СУДЕЙСКИЙ ПРОТОКОЛ (олимпийская система)</div>
    <div class="center">схваток по САМБО</div>
    <div class="date">"_____" ________________ 20__ г.</div>
  
    <div class="age-weights">
      <div>Года: {{ age.min_year }} - {{ age.max_year }}</div>
      <div>Вес: {{ weight.name }} кг</div>
    </div>
  
    <turnir-tree [matches]="matches"></turnir-tree>
  
    <!-- <pre>{{ weight | json}}</pre> -->
  
    <div class="footer">
      <div>Главный судья _____________________ /_________________________/</div>
      <div>Секретарь__________________________ /_________________________/</div>
      <div>Руководитель ковра ________________ /_________________________/</div>
      <div>Технический секретарь _____________ /_________________________/</div>
    </div>
  </div>
<div class="protocol">
  <div class="header">
    <div class="left logo">
      <img alt="logo" src="/assets/images/new_logo_2.svg" />
    </div>

    <div>
      <div class="title">СУДЕЙСКИЙ ПРОТОКОЛ<br>схваток по <span class="sport-name">{{ turnir?.sport?.name }}</span></div>
      <div class="center"></div>
    </div>

    <div class="right logo">
      <img alt="logo" src="/assets/images/new_logo_2.svg" />
    </div>
  </div>

  <div class="turnir-name">{{ turnir.name }}</div>

  <div class="age-weights">
    <div>Года: <span class="bold">{{ age.min_year }} - {{ age.max_year }}</span></div>
    <div>Вес: <span class="bold">{{ weight.name }} кг</span></div>
    <div class="date">"_____" ________________ 20__ г.</div>
  </div>

  <div *ngFor="let lap of getLaps(weight); let lapNum = index">
    <div class="lap-title">Круг {{ lap.lap }}</div>

    <table class="battle" *ngFor="let b of lap.battles">
      <tr>
        <td class="number">№</td>
        <td class="name">{{ getSitNameById(b.sit1_id) }}</td>
        <!-- <td>Клуб/регион</td> -->
        <td></td>
        <td class="sum"></td>
        <td class="result"></td>
        <td rowspan="2" class="time">Время</td>
        <td rowspan="2" class="signs">Подписи</td>
      </tr>

      <tr>
        <td class="number">№</td>
        <td class="name">{{ getSitNameById(b.sit2_id) }}</td>
        <!-- <td>Клуб/регион</td> -->
        <td></td>
        <td class="sum"></td>
        <td class="result"></td>
      </tr>
    </table>
  </div>

  <!-- <pre>{{ weight | json}}</pre> -->

  <div class="footer-signs">
    <div class="sign">Главный судья _____________________ /_________________________/</div>
    <div class="sign">Секретарь_____________________ /_________________________/</div>
    <div class="sign">Руководитель ковра _____________________ /_________________________/</div>
    <div class="sign">Технический секретарь _____________________ /_________________________/</div>
  </div>

  <div class="turnir-name">Результаты</div>

  <table class="battle">
    <thead>
      <th class="number">№</th>
      <th>ФИО</th>
      <th class="sum">Очки</th>
      <th class="result">Место</th>
    </thead>
    <tr *ngFor="let s of weight.sportsmens">
      <td class="number">№</td>
      <td>{{ getSitNameById(s.sit_id) }}</td>
      <td class="sum"></td>
      <td class="result"></td>
    </tr>
  </table>
</div>

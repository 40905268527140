<div class="weight-container">
    <div>
        <app-weight-sportmens-list
            [list]="list"
            (onRowSelect)="onRowSelect($event)"
            (onRowDelete)="onRowDelete($event)">
        </app-weight-sportmens-list>
       
        <div class="button-bar-holder">
            <div class="button-bar">
                <p-button (click)="show()" icon="pi pi-plus" label="Добавить спортсмена">
                </p-button>
                <p-button (click)="stopWeightning()" icon="pi pi-stop-circle"
                    styleClass="p-button-warning"
                    label="Завершить взвешивание">
                </p-button>
            </div>
        </div>
    </div>
</div>

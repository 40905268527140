import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class DatesService {
  private static dateFormat = 'yyyy-MM-dd';

  constructor(private datePipe: DatePipe) { }

  dateToServerFormat(date: Date)  {
    return this.datePipe.transform(date, DatesService.dateFormat);
  }

  dateFromServerFormat(str: string): Date  {
    return new Date(Date.parse(str));
  }
}

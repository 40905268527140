import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { TurnirOrganizerEvent } from 'src/app/common/models/Organization';
import { Turnir, TurnirHelpers } from 'src/app/common/models/Turnir';
import { NetworkService } from 'src/app/services/network.service';

@Component({
  selector: 'app-turnir-organizer',
  templateUrl: './turnir-organizer.component.html',
  styleUrls: ['./turnir-organizer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TurnirOrganizerComponent implements OnInit {
  STARTED = 1;

  WEIGHTNING = 20;
  WEIGHTNING_OVER = 21;

  GROUPING = 33;
  GROUPING_OVER = 35;

  PROTOCOLS_CREATED = 43;

  _turnirId: number = -1;
  status: any = {};

  @Input()
  set turnirId(id: number) {
    this._turnirId = id;
  }

  get turnirId() {
    return this._turnirId;
  }

  events: Array<TurnirOrganizerEvent> = [];
  turnir: Turnir = {} as Turnir;

  constructor(private networkService: NetworkService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private router: Router) {
      this.route.paramMap.subscribe(paramMap => {
        if (paramMap != null) {
          if (isNaN(Number(paramMap.get('turnirid')))) {
            this.turnirId = 0;
          } else {
            this.turnirId = Number(paramMap.get('turnirid'));
            this.getTurnir(this.turnirId);
          }
        }
      })
  }

  getTurnir(id: number) {
    this.networkService.getTurnir(id)
    .subscribe(result => {
      this.turnir = (result as any) as Turnir || {} as Turnir;
      this.cdr.markForCheck();
    },
    
    error => {
      this.messageService.add({ key: 'main', severity: 'error', summary: 'Ошибка', 
        detail: error.status + ' ' + error.statusText});
    });
  }

  // getCurrentTurnirStatus() {
  //   return this.status.currentStatus;
  // }

  getCurrentTurnirStatus(): number {
    return +this.status.currentStatus;
  }

  getCurrentTurnirStatusName(): string {
    switch(this.getCurrentTurnirStatus()) {
      case this.STARTED: return 'Начат';
      case this.WEIGHTNING: return 'Взвешивание';
      case this.WEIGHTNING_OVER: return 'Завершено взвешивание';
      case this.GROUPING: return 'Группирование';
      case this.GROUPING_OVER: return 'Завершено группирование';
    }
    return this.getCurrentTurnirStatus().toString();
  }

  private getTurnirStatus() {
    this.networkService.turnirOrganizer_getTurnirStatus(this.turnirId)
    .subscribe(result => {
    this.status = result.result ? result.result : { currentStatus: 1 /*TurnirOrganizerComponent.STATUS_STARTED*/ };
      this.cdr.markForCheck();
    },
    
    error => {
      this.messageService.add({ key: 'main', severity: 'error', summary: 'Ошибка', 
        detail: error.status + ' ' + error.statusText});
    });
  }

  ngOnInit(): void {
    this.getTurnirStatus();
  }

  startWeightning() {
    this.networkService.turnirOrganizer_startWeightning(this.turnirId)
    .subscribe(
      result => {
        this._openForm();
        this.cdr.markForCheck();
      },
      error => {
        console.log('startWeightning result', error);
        this.messageService.add({ key: 'main', severity: 'error', summary: 'Ошибка', 
          detail: error.status + ' ' + error.statusText});
        this.cdr.markForCheck();
      }
    );
  }

  onOpenForm() {
    this._openForm();
  }

  _openForm() {
    this.router.navigate(['/turnirs', this.turnirId, 'weighing']);
  }

  stopWeightning() {
    this.networkService.turnirOrganizer_stopWeightning(this.turnirId)
    .subscribe(
      result => {
        this.getTurnirStatus();
        this.cdr.markForCheck();
      },
      error => {
        this.getTurnirStatus();
        this.messageService.add({ key: 'main', severity: 'error', summary: 'Ошибка', 
          detail: error.status + ' ' + error.statusText});
        this.cdr.markForCheck();
      }
    );
  }

  generateWeightningProtocols() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/report', this.turnirId, 'weightning-protocols'])
    );
  
    window.open(url, '_blank');
  }

  onShowRegistration() {
    this.router.navigate(['/turnirs', this.turnirId, 'register']);
  }

  isRegistrationAllowed(): boolean {
    return TurnirHelpers.isRegistrationAllowed(this.turnir);
  }

  onGroupingStartClick() {
    this.networkService.turnirOrganizer_startGrouping(this.turnirId)
    .subscribe(
      result => {
        this.openGroupingForm();
        this.cdr.markForCheck();
      },
      error => {
        this.getTurnirStatus();
        this.messageService.add({ key: 'main', severity: 'error', summary: 'Ошибка', 
          detail: error.status + ' ' + error.statusText});
        this.cdr.markForCheck();
      }
    );
  }

  onGroupingFormClick() {
    this.openGroupingForm();
  }

  private openGroupingForm() {
    this.router.navigate(['/turnirs', this.turnirId, 'grouping']);
  }

  onGenerateProtocolsClick() {
    this.networkService.turnirOrganizer_generateProtocols(this.turnirId)
    .subscribe(
      result => {
        this.openProtocols();
        this.cdr.markForCheck();
      },
      error => {
        this.getTurnirStatus();
        this.messageService.add({ key: 'main', severity: 'error', summary: 'Ошибка', 
          detail: error.status + ' ' + error.statusText + (error.error.message ? (' ' + error.error.message) : '' )});
        this.cdr.markForCheck();
      }
    );
  }

  openProtocols() {
    this.router.navigate(['/report', this.turnirId, 'protocols']);
  }

  
}

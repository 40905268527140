import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TurnirRoutingModule } from './turnir-routing.module';
import { TurnirListComponent } from './turnir-list/turnir-list.component';
import { TurnirDetailComponent } from './turnir-detail/turnir-detail.component';
import { ServicesModule } from '../services/services.module';
import { WeighingComponent } from './weighing/weighing.component';
import { GroupingComponent } from './grouping/grouping.component';
import { TableModule } from 'primeng/table';
import { PaginatorModule } from 'primeng/paginator';

import { InputTextModule } from 'primeng/inputtext';
import { FieldsetModule } from 'primeng/fieldset';
import { PanelModule } from 'primeng/panel';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogService, DynamicDialogConfig, DynamicDialogModule, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputNumberModule } from 'primeng/inputnumber';
import { FileUploadModule } from 'primeng/fileupload';
import { HttpClientModule } from '@angular/common/http';
import { RegisterComponent } from './register/register/register.component';
import { ApplicationsComponent } from './register/applications/applications.component';
import { RegisteredAppsCommonListComponent } from './register/registered-apps-common-list/registered-apps-common-list.component';
import { StatModule } from '../components/stat/stat.module';
import { DataViewModule } from 'primeng/dataview';
import { CardModule } from 'primeng/card';
import { TotalStatComponent } from './register/total-stat/total-stat.component';
import { TurnirGuard } from './turnir-detail/turnir.guard';
import { WeightSportmensListComponent } from './weighing/weight-sportmens-list/weight-sportmens-list.component';
import { WeightSportmensDetailComponent } from './weighing/weight-sportmens-detail/weight-sportmens-detail.component';
import { ToastModule } from 'primeng/toast';
import { TurnirOrganizerComponent } from './turnir-organizer/turnir-organizer.component';
import { WeightProtocolsComponent } from './weighing/weight-protocols/weight-protocols.component';
import { DragDropModule } from 'primeng/dragdrop';
import { TurnirTreeComponent } from '../report/components/protocols/turnir-tree/turnir-tree.component';
import { ProtocolsComponent } from '../report/components/protocols/protocols.component';
import { OlimpicProtocolComponent } from '../report/components/protocols/olimpic-protocol/olimpic-protocol.component';
import { RoundRobinProtocolComponent } from '../report/components/protocols/round-robin-protocol/round-robin-protocol.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

@NgModule({
  declarations: [
    TurnirListComponent,
    TurnirDetailComponent,
    WeighingComponent,
    GroupingComponent,
    RegisterComponent,
    ApplicationsComponent,
    RegisteredAppsCommonListComponent,
    TotalStatComponent,
    WeightSportmensListComponent,
    WeightSportmensDetailComponent,
    TurnirOrganizerComponent,
    WeightProtocolsComponent,
    TurnirTreeComponent,
    ProtocolsComponent,
    RoundRobinProtocolComponent,
    OlimpicProtocolComponent,
  ],
  imports: [
    CommonModule,
    TurnirRoutingModule,
    ServicesModule,
    TableModule,
    PaginatorModule,
    InputTextModule,
    DynamicDialogModule,
    DropdownModule,
    CalendarModule,
    CheckboxModule,
    ToastModule,
    InputNumberModule,
    FileUploadModule,
    HttpClientModule,
    TurnirRoutingModule,
    StatModule,
    DataViewModule,
    CardModule,
    DragDropModule,
    PanelModule,
    FieldsetModule,
    CKEditorModule
  ],
  providers: [ TurnirGuard, DialogService, DynamicDialogRef, DynamicDialogConfig ]
})
export class TurnirModule {}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { AppStateService } from 'src/app/services/app-state.service';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  constructor(private loginService: LoginService, private appStateService: AppStateService, private cd: ChangeDetectorRef) {}

  logout() {
    this.loginService.logout();
  }
  toggleSidebar() {
    if (this.appStateService.sidebarWidth.value > 0) {
      this.appStateService.sidebarWidth.next(0);
    } else {
      this.appStateService.sidebarWidth.next(300);
    }
  }
}

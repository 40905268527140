import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NetworkService } from 'src/app/services/network.service';

@Component({
  selector: 'app-applications',
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class ApplicationsComponent implements OnInit {
  _turnirId = 0;

  @Input()
  set turnirId(v: number) {
    this._turnirId = v;
    this.getAllApplicationsForTurnir();
  }

  get turnirId(): number {
    return this._turnirId;
  }

  list: Array<any> = [];
  registered: Array<any> = [];
  confirmed: Array<any> = [];
  rejected: Array<any> = [];

  constructor(private networkService: NetworkService,
    private cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
  }

  getAllApplicationsForTurnir() {
    this.list = [];
    this.rejected = [];
    this.confirmed = [];
    this.registered = [];
    this.networkService.getAllApplicationsForTurnir(this.turnirId)
    .subscribe(list => {
      this.list = list;
      this.registered = list.filter((e:any) => {
        return e['state']['external_id'] === 'REGISTERED';
      });
      this.confirmed = list.filter((e:any) => {
        return e['state']['external_id'] === 'CONFIRMED';
      });
      this.rejected = list.filter((e:any) => {
        return e['state']['external_id'] === 'REJECTED';
      });
      this.cdr.markForCheck();
    });
  }

  countSportsmens(): number {
    if (!this.list) {
      return 0;
    }

    let sum = 0;
    this.list.forEach(e => {
      if (e['sportsmens']) {
        sum += e['sportsmens'].length;
      }
    });

    return sum;
  }

  onConfirm(appId: number) {
    this.networkService.confirmApplication(this.turnirId, appId)
    .subscribe(d => {
      this.getAllApplicationsForTurnir();
      this.cdr.markForCheck();
    });
  }

  onReject(appId: number) {
    this.networkService.rejectApplication(this.turnirId, appId)
    .subscribe(d => {
      this.getAllApplicationsForTurnir();
      this.cdr.markForCheck();
    });
  }
}

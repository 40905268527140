import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { delay } from 'rxjs/operators';
import { ConfigService } from './config.service';

export class Loader {
  progressIncrement: Subject<number>;
  progress: BehaviorSubject<number>;
  timeout: number = -1;

  constructor() {
    this.progress = new BehaviorSubject<number>(0);
    this.progressIncrement = new Subject<number>();
  }

  setProgress(value: number) {
    this.progressIncrement.next(value - this.progress.getValue());
    this.progress.next(value);
    window.clearTimeout(this.timeout);
    if (value !== 100) {
      this.timeout = window.setTimeout(() => {
        this.setProgress(100);
      }, 60000);
    }
    if (value === 100) {
      this.progress.complete();
    }
  }
}

@Injectable({
  providedIn: 'root',
})
export class AppStateService {
  sidebarWidth: BehaviorSubject<number> = new BehaviorSubject(300);
  totalProgress: BehaviorSubject<number>;
  maxTotalProgress: BehaviorSubject<number>;

  loadingState: BehaviorSubject<string> = new BehaviorSubject('start');

  filters: Map<string, any>;
  filtersDefaults: any = {
    startDate: '-24h',
    endDate: 'now',
    pageSize: 24,
    pageIndex: 0,
  };

  constructor(private configService: ConfigService) // ,private parseDateService: ParseDateService
  {
    /*this.configService.inited.subscribe(data => {
      if (data) {
        if (this.configService.minDate) {
          this.filtersDefaults.startDate = moment(this.configService.minDate).format(this.parseDateService.outFormat);
        }

        if (this.configService.maxDate) {
          this.filtersDefaults.endDate = moment(this.configService.maxDate).format(this.parseDateService.outFormat);
        }
      }
    });
    */
    this.filters = new Map();

    this.totalProgress = new BehaviorSubject<number>(0);
    this.maxTotalProgress = new BehaviorSubject<number>(0);

    combineLatest([this.totalProgress, this.maxTotalProgress]).subscribe(([totalProgress, maxTotalProgress]) => {
      if (totalProgress === maxTotalProgress && maxTotalProgress > 0) {
        this.loadingState.next('hide');
      }
    });
    combineLatest([this.totalProgress, this.maxTotalProgress])
      .pipe(delay(200))
      .subscribe(([totalProgress, maxTotalProgress]) => {
        if (totalProgress !== maxTotalProgress && maxTotalProgress > 0) {
          this.loadingState.next('start');
        }
      });

    combineLatest([this.totalProgress, this.maxTotalProgress])
      .pipe(delay(400))
      .subscribe(([totalProgress, maxTotalProgress]) => {
        if (totalProgress === maxTotalProgress && totalProgress === this.maxTotalProgress.getValue()) {
          this.loadingState.next('none');
        }
      });
  }
  setFilter(key: string, value: any) {
    this.filters.set(key, value);
  }
  getFilter(key: string, def: any): any {
    if (this.filtersDefaults[key] !== undefined) {
      return this.filtersDefaults[key];
    }
    if (def) {
      return def;
    }
    return undefined;
  }
  getLoader(): Loader {
    const loader: Loader = new Loader();
    this.maxTotalProgress.next(this.maxTotalProgress.getValue() + 100);
    loader.progressIncrement.subscribe(value => {
      this.totalProgress.next(this.totalProgress.getValue() + value);
    });
    this.totalProgress.next(this.totalProgress.getValue());
    return loader;
  }
}

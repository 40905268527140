import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { TurnirTreeBattle } from 'src/app/common/models/Protocols';

@Component({
  selector: 'turnir-tree',
  templateUrl: './turnir-tree.component.html',
  styleUrls: ['./turnir-tree.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TurnirTreeComponent implements OnInit {
  @Input()
  set matches(v: TurnirTreeBattle[]) {
    this._matches = v;
    this.makeLisFromMatches();
    this.cdr.markForCheck();
  }

  get matches() {
    return this._matches;
  }
  
  
  _matches: TurnirTreeBattle[] = [];

  lis: any[] = [];

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.makeLisFromMatches();
  }

  private makeLisFromMatches() {
    const pp:any[] = [];
    let p: any[] = [{ type: 'spacer', class: 'spacer' }];

    console.log("mmmmm", this.matches);

    this.matches.forEach(m => {
      p.push({ type: 'c1', class: "game game-top", c1: m.c1, p1: ''},);
      p.push({ type: 'spacer', class: 'game game-spacer' },);
      p.push({ type: 'c2', class: "game game-bottom", c2: m.c2, p2: ''},);
      p.push({ type: 'spacer', class: 'spacer' });
    });

    console.log('ppppp', p)

    pp.push(p);

    let maxMatchesCount = this.matches.length;
    
    while (maxMatchesCount >= 2) {
      maxMatchesCount = maxMatchesCount / 2;
      p = [{ type: 'spacer', class: 'spacer' }];

      for(let i = 0; i < maxMatchesCount; i++) {
        p.push({ type: 'c1', class: "game game-top", c1: '', p1: ''},);
        p.push({ type: 'spacer', class: 'game game-spacer' },);
        p.push({ type: 'c2', class: "game game-bottom", c2: '', p2: ''},);
        p.push({ type: 'spacer', class: 'spacer' });
      }
      pp.push(p);
    }

    this.lis = pp;
    this.cdr.markForCheck();
  }
/*
  lisOld = [[
    { type: 'spacer', class: 'spacer' },
    
    { type: 'c1', class: "game game-top winner", c1: 'ЦСКА', p1: 80},
    { type: 'spacer', class: 'game game-spacer' },
    { type: 'c2', class: "game game-bottom", c2: 'Крылья Советов', p2: 47},
    { type: 'spacer', class: 'spacer' },

    { type: 'c1', class: "game game-top winner", c1: 'Динамо', p1: 84},
    { type: 'spacer', class: 'game game-spacer' },
    { type: 'c2', class: "game game-bottom", c2: 'Пари НН', p2: 72},
    { type: 'spacer', class: 'spacer' },

    { type: 'c1', class: "game game-top", c1: 'Сочи', p1: 55},
    { type: 'spacer', class: 'game game-spacer' },
    { type: 'c2', class: "game game-bottom winner", c2: 'Спартак', p2: 68},
    { type: 'spacer', class: 'spacer' },

    { type: 'c1', class: "game game-top winner", c1: 'Краснодар', p1: 64},
    { type: 'spacer', class: 'game game-spacer' },
    { type: 'c2', class: "game game-bottom", c2: 'Ахмат', p2: 44},
    { type: 'spacer', class: 'spacer' },


    { type: 'c1', class: "game game-top winner", c1: 'Балтика', p1: 54},
    { type: 'spacer', class: 'game game-spacer' },
    { type: 'c2', class: "game game-bottom", c2: 'Ростов', p2: 52},
    { type: 'spacer', class: 'spacer' },

    { type: 'c1', class: "game game-top", c1: 'Факел', p1: 65},
    { type: 'spacer', class: 'game game-spacer' },
    { type: 'c2', class: "game game-bottom winner", c2: 'Рубин', p2: 69},
    { type: 'spacer', class: 'spacer' },

    { type: 'c1', class: "game game-top winner", c1: 'Локомотив', p1: 67},
    { type: 'spacer', class: 'game game-spacer' },
    { type: 'c2', class: "game game-bottom", c2: 'Оренбург', p2: 63},
    { type: 'spacer', class: 'spacer' },

     { type: 'c1', class: "game game-top winner", c1: 'Зенит', p1: 80},
    { type: 'spacer', class: 'game game-spacer' },
    { type: 'c2', class: "game game-bottom", c2: 'Урал', p2: 47},
    { type: 'spacer', class: 'spacer' },
  
],
[
  { type: 'spacer', class: 'spacer' },
  
  { type: 'c1', class: "game game-top winner", c1: 'ЦСКА', p1: 3},
  { type: 'spacer', class: 'game game-spacer' },
  { type: 'c2', class: "game game-bottom", c2: 'Динамо', p2: 1},
  { type: 'spacer', class: 'spacer' },

  { type: 'c1', class: "game game-top winner", c1: 'Спартак', p1: 1},
  { type: 'spacer', class: 'game game-spacer' },
  { type: 'c2', class: "game game-bottom", c2: 'Краснодар', p2: 0},
  { type: 'spacer', class: 'spacer' },

  { type: 'c1', class: "game game-top", c1: 'Балтика', p1: 1},
  { type: 'spacer', class: 'game game-spacer' },
  { type: 'c2', class: "game game-bottom winner", c2: 'Рубин', p2: 2},
  { type: 'spacer', class: 'spacer' },

  { type: 'c1', class: "game game-top", c1: 'Локомотив', p1: 1},
  { type: 'spacer', class: 'game game-spacer' },
  { type: 'c2', class: "game game-bottom winner", c2: 'Зенит', p2: 2},
  { type: 'spacer', class: 'spacer' },

],
[
{ type: 'spacer', class: 'spacer' },

{ type: 'c1', class: "game game-top winner", c1: 'ЦСКА', p1: 5},
{ type: 'spacer', class: 'game game-spacer' },
{ type: 'c2', class: "game game-bottom", c2: 'Спартак', p2: 0},
{ type: 'spacer', class: 'spacer' },

{ type: 'c1', class: "game game-top", c1: 'Рубин', p1: 0},
{ type: 'spacer', class: 'game game-spacer' },
{ type: 'c2', class: "game game-bottom winner", c2: 'Зенит', p2: 1},
{ type: 'spacer', class: 'spacer' },

],
[
{ type: 'spacer', class: 'spacer' },

{ type: 'c1', class: "game game-top winner", c1: 'ЦСКА', p1: 2},
{ type: 'spacer', class: 'game game-spacer' },
{ type: 'c2', class: "game game-bottom", c2: 'Зенит', p2: 0},
{ type: 'spacer', class: 'spacer' },



]];
*/
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AppSettings } from 'src/app/appSettings';
import { SportsmenInTurnir } from 'src/app/common/models/Organization';
import { TurnirAge, TurnirAgeWeight } from 'src/app/common/models/Turnir';
import { NetworkService } from 'src/app/services/network.service';

@Component({
  selector: 'app-grouping',
  templateUrl: './grouping.component.html',
  styleUrls: ['./grouping.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupingComponent implements OnInit {
  draggedElement: any = null;
  sitList: Array<SportsmenInTurnir> = [];
  groupsData = [];
  groupsFormattedData = [];
  _turnirId: number = -1;
  selectedProducts = [];

  static settings = {
    organizer: {
        grouping: {
            round: {
                maxSportsmenInGroup: 6
            },
            olimpic: {
              maxSportsmenInGroup: 32
            }
        }
    }
}

  data: any = {
    hideEmptyGroups: true
  };

  systems = [
    { id: 1, name: 'Круговая'},
    { id: 2, name: 'Олимпийская'}
  ];

  set turnirId(id: number) {
    this._turnirId = id;
  }

  get turnirId() {
    return this._turnirId;
  }

  constructor(private networkService: NetworkService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private messageService: MessageService,
    private router: Router,
    private confirmationService: ConfirmationService) {
    this.route.paramMap.subscribe(paramMap => {
      if (paramMap != null) {
        if (isNaN(Number(paramMap.get('turnirid')))) {
          this.turnirId = 0;
        } else {
          this.turnirId = Number(paramMap.get('turnirid'));
        }
      }
    })
  }

  ngOnInit(): void {
    this.getSITList();
    this.getData();
  }

  getSITList() {
    this.networkService.loadSportsmenInTurnirList(this.turnirId)
    .subscribe(
      result => {
        const l = [];

        for(const sit of result.result.list) {
          l[sit.id] = sit;
        }

        this.sitList = l;
        this.cdr.markForCheck();
      },
      error => {
        this.messageService.add({ key: 'main', severity: 'error', summary: 'Ошибка', detail: error.message });
      }
    )
  }

  getData() {
    this.networkService.turnirOrganizer_getGroupingData(this.turnirId)
    .subscribe(result => {
      this.groupsData = result.result;
      this.groupsFormattedData = this.formatGroupingData(this.groupsData);
      this.cdr.markForCheck();
    },
    error => {
      this.messageService.add({ key: 'main', severity: 'error', summary: 'Ошибка', detail: error.message });
    });
  }

  drop(event: DragEvent, weight: any) {
    console.log('drop', event, weight);
    if (this.draggedElement) {
      const sit = this.draggedElement.sit;

      sit.turnir_age_weight_id = weight.id;
        weight['sportsmens'].push(sit);
        


        const ind = this.findIndex(sit, this.draggedElement.sourceWeight.sportsmens);
        if (ind >= 0) {
          this.draggedElement.sourceWeight.sportsmens.splice(ind, 1);
        }
        this.draggedElement = null;
        this.cdr.markForCheck();
    }
  }

  dragStart(event: DragEvent, sit: any, weight: any) {
      this.draggedElement = {
        sit: sit,
        sourceWeight: weight
      };
      console.log('drag start', event, this.draggedElement);
  }

    dragEnd() {
      console.log("drag stop");
      this.draggedElement = null;
    }

    findIndex(sit: any, sitList: any[]) {
        let index = -1;
        for(let i = 0; i < sitList.length; i++) {
            if (sit['id'] === sitList[i].id) {
                return i;
            }
        }
        return -1;
    }

    getSitById(sitId: number): SportsmenInTurnir {
      if (this.sitList[sitId] !== null) {
        return this.sitList[sitId];
      }

      throw new Error(`SportsmenInTurnir with id=${sitId} not found`);
    }

    getSitDescription(sitId: number) {
      const sit = this.getSitById(sitId);
      const d = new Date(sit.birthdate);
      return `${d.getFullYear()} ${sit.lastname} ${sit.firstname} ${sit.weight} кг `;
    }

    formatGroupingData(data: any) {
      data.sort((a: any, b: any) => a.min_year - b.min_year);

      // бред какой-то )))
      for(const age of data) {
        age['weights'].sort((a:any, b: any) => a.min_weight - b.min_weight);

        delete age['_'];

        let wMap = age['weights'].map((x: any) => x['name']);

        age['_'] = {
          weightGroups: []
        };

        const unique = (arr: []) => [...new Set(arr)];
        wMap = unique(wMap);

        for(let i = 0; i < wMap.length; i++) {
          const wName = wMap[i];
          const wArr: any = {
            descr: undefined,
            groups: []
          };
          age['_']['weightGroups'].push(wArr);
          
          for(const w of age['weights']) {
            if (wName === w.name) {
              if (!wArr['descr']) {
                wArr['descr'] = w;
              }
              wArr['groups'].push(w);
            }
          }
        }
      }


      return data;
    }

    onAddGroup(age: any, sourceGroup: any, num: number) {
      const g = {
        max_weight: sourceGroup.max_weight,
        min_weight: sourceGroup.min_weight,
        name: sourceGroup.name,
        num,
        sportsmens: [],
        system_id: sourceGroup.system_id,
        turnir_age_id: sourceGroup.turnir_age_id
      }

      age.weights.push(g);
      this.groupsFormattedData = this.formatGroupingData(this.groupsData);
      this.cdr.markForCheck();
    }

    onSaveClick() {
      this.saveGroup()
      .subscribe(result=> {
        this.getData();
        this.cdr.markForCheck();
      },
        error => {
          this.messageService.add({ key: 'main', severity: 'error', summary: 'Ошибка', detail: error.message });
          this.cdr.markForCheck();
        });
    }

    saveGroup() {
      return this.networkService.turnirOrganizer_updateGroupingData(this.turnirId, this.groupsData)
    }

    onDeleteGroup(age: any, group: any) {
      // удалить можно только пустую группу
      if (group['sportsmens'].length > 0) {
        this.messageService.add({ key: 'main', severity: 'error', summary: 'Ошибка удаления группы',
          detail: 'Для удаления группы перенсите из нее всех спортсменов' });
        return;
      }

      // группу которую еще не сохранили удалим локально
      if (!group.id) {
        this.deleteUnsavedGroup(age, group);
        this.formatGroupingData(this.groupsData);
        return;
      }

      this.deleteExistingGroup(age, group);
    }

    deleteUnsavedGroup(age: any, group: any) {
      let ind = -1;
      for(let i  = 0; i < age['weights'].length; i++) {
        const w = age['weights'][i];
        if (group === w) {
          ind = i;
          break; 
        }
      }

      if (ind >= 0) {
        age['weights'].splice(ind, 1);
      }
    }

    deleteExistingGroup(age: any, group: any) {
      this.saveGroup()
      .toPromise()
      .then(result => {   
        this.cdr.markForCheck();     
        return  this.networkService.turnirOrganizer_deleteWeightGroup(this.turnirId, group.id).toPromise();
      }).then(
        result => { 
          this.cdr.markForCheck();
          this.getData();
        },
        error => {
          console.log('deleteExistingGroup', error)
          this.messageService.add({ key: 'main', severity: 'error', summary: 'Ошибка удаления группы',
          detail: error.message });
        }
      );
    }

    step2() {
      this.confirmationService.confirm({
        message: `Вы уверены что хотите группировку спортсменов? 
          Вы сможете возобновить группировку позжеб если это будет необходимо.`,
        header: 'Завершение группирования',
        icon: 'pi pi-info-circle',
        acceptLabel: 'Да, завершить',
        rejectButtonStyleClass: 'p-button-text',
        rejectLabel: 'Нет',
        defaultFocus: 'reject',
        accept: () => {
          this.saveGroup()
          .subscribe(data => {
            this.networkService.turnirOrganizer_stopGrouping(this.turnirId)
            .subscribe(
              result => {
                this.router.navigate(['/turnirs', this.turnirId, 'organize']);
                this.cdr.markForCheck();
              },
              error => {
                this.messageService.add({ key: 'main', severity: 'error', summary: 'Ошибка', 
                  detail: error.status + ' ' + error.statusText});
                this.cdr.markForCheck();
              }
            );
          })
        }
    });
      
      
      /*
      this.networkService.turnirOrganizer_stopGrouping(this.turnirId)
      .subscribe(
        result => {
          
          
          
          
          
          
          
          
          
          
          
          
          
        },
        error => {
          this.messageService.add({ key: 'main', severity: 'error', summary: 'Ошибка', 
            detail: error.status + ' ' + error.statusText});
          this.cdr.markForCheck();
        }
      );*/
    }

    onGroupingStopClick() {
      if (this.groupsData[0]['weights'][0]['sportsmens']['length'] > 0) {
        this.confirmationService.confirm({
          message: `У вас остались нераспределенные спортсмены. Для них НЕ БУДУТ сформированы протоколы схваток.
            Вы точно хотите отменить участие таких спортсменов?.`,
          header: 'Нераспределенные спортсмены',
          icon: 'pi pi-info-circle',
          acceptLabel: 'Да, они не участвуют',
          rejectButtonStyleClass: 'p-button-text',
          rejectLabel: 'Нет, надо исправить',
          defaultFocus: 'reject',
          accept: () => {
            setTimeout(() => this.step2(), 1000);
          }
        });
      } else {
        this.step2();
      }
    }

    checkUngroupedSportsmens() {
      if (this.groupsData[0]['weights'][0]['sportsmens']['length'] > 0) {
        this.confirmationService.confirm({
          message: `У вас остались нераспределенные спортсмены. Для них НЕ БУДУТ сформиованы протоколы схваток.
            Вы точно хотите отменить участие таких спортсменов?.`,
          header: 'Нераспределенные спортсмены',
          icon: 'pi pi-info-circle',
          acceptLabel: 'Да, они не участвуют',
          rejectButtonStyleClass: 'p-button-text',
          rejectLabel: 'Нет, надо исправить',
          defaultFocus: 'reject',
          accept: () => {}
        });
      }
    }

    onHideEmptyGroupsChanged() {
      this.cdr.markForCheck();
    }

    mustShowGroup(g: TurnirAgeWeight) {
      return !this.data.hideEmptyGroups || (g && g.sportsmens && g.sportsmens.length);
    }

    mustShowAgeWeight(wg: any) {
      return !this.data.hideEmptyGroups || wg && wg.groups && (wg.groups.length > 1 || wg.groups[0].sportsmens && wg.groups[0].sportsmens.length);
    }

    canFinishGrouping() {
      let age: any;
      console.log("canFinishGrouping")
      for(age of this.groupsFormattedData) {
        for(let wg of age['_']['weightGroups']) {
          for(let g of wg['groups']) {
            if (this.groupHasErrors(g)) {
              return false;
            }
          }
        }
      }
      return true;
    }

    groupHasErrors(g: any): boolean {
      return this.getGroupErrors(g).length > 0;
    }

    getGroupErrors(g: any): String[] {
      const arr: String[] = [];

      if (g.sportsmens.length === 0 || (g.max_weight === 0 && g.min_weight === 0)) {
        return arr;
      }

      if (g.sportsmens.length === 1) {
        arr.push("Минимум 2 спортсмена в группе")
      }

      if (g.system_id === 1 && g.sportsmens.length > GroupingComponent.settings.organizer.grouping.round.maxSportsmenInGroup) {
        arr.push("Для круговой системы max спортсменов в группе: " + GroupingComponent.settings.organizer.grouping.round.maxSportsmenInGroup)
      }

      if (g.system_id === 1 && g.sportsmens.length > GroupingComponent.settings.organizer.grouping.olimpic.maxSportsmenInGroup) {
        arr.push("Для олимпийской системы max спортсменов в группе: " + GroupingComponent.settings.organizer.grouping.olimpic.maxSportsmenInGroup)
      }


      console.log("gggg", g);
      return arr;
    }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit
} from '@angular/core';
import { SportsmenInTurnir } from 'src/app/common/models/Organization';
import { TurnirTreeBattle } from 'src/app/common/models/Protocols';
import { TurnirAge, TurnirAgeWeight } from 'src/app/common/models/Turnir';

@Component({
  selector: 'olimpic-protocol',
  templateUrl: './olimpic-protocol.component.html',
  styleUrls: ['./olimpic-protocol.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OlimpicProtocolComponent implements OnInit {
  @Input()
  set age(v: TurnirAge) {
    this._age = v;
    this.cdr.markForCheck();
  }

  get age() {
    return this._age;
  }
  _age: TurnirAge = {} as TurnirAge;

  @Input()
  set weight(v: TurnirAgeWeight) {
    this._weight = v;
    this.generateMatches();
    this.cdr.markForCheck();
  }

  get weight() {
    return this._weight;
  }

  _weight: TurnirAgeWeight = {} as TurnirAgeWeight;

  @Input()
  set sitList(v: SportsmenInTurnir[]) {
    this._sitList = v;
    this.generateMatches();
  }

  get sitList() {
    return this._sitList;
  }
  _sitList: Array<SportsmenInTurnir> = [];

  matches: TurnirTreeBattle[] = [
    { c1: 'ЦСКА', c2: 'Крылья Советов', p1: 79, p2: 48 },
    { c1: 'Динамо', c2: 'Пари НН', p1: 84, p2: 72 },
    { c1: 'Сочи', c2: 'Спартак', p1: 55, p2: 68 },
    { c1: 'Краснодар', c2: 'Ахмат', p1: 64, p2: 44 },
    { c1: 'Балтика', c2: 'Ростов', p1: 54, p2: 52 },
    { c1: 'Факел', c2: 'Рубин', p1: 65, p2: 54 },
    { c1: 'Локомотив', c2: 'Орнебург', p1: 67, p2: 63 },
    { c1: 'Зенит', c2: 'Урал', p1: 73, p2: 61 }
  ];

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.generateMatches();
  }

  private generateMatches() {
    const ret: TurnirTreeBattle[] = [];


    console.log('olimpic matches', this.weight.battles)
    
    if (this.sitList && this.sitList.length > 0) {
      this.weight.battles.forEach(b => {
        ret.push({
          c1: this.getSitNameById(b.sit1_id),
          c2: this.getSitNameById(b.sit2_id),
          p1: '',
          p2: ''
        });
      });
    }

    console.log('matches', ret)

    this.matches = ret;
    this.cdr.markForCheck();
  }

  getSitById(sitId: number): SportsmenInTurnir | null {
    if (sitId == null) {
      return null;
    }

    if (this.sitList[sitId]) {
      return this.sitList[sitId];
    }

    return null;
  }

  getSitNameById(sitId: number): string {
    const sit = this.getSitById(sitId);

    if (!sit) {
      return '-';
    }
    return (
      (sit.lastname ? sit.lastname : '') +
      ' ' +
      (sit.firstname ? sit.firstname : '')
    );
  }
}

<h4>Зарегистрированные спортсмены</h4>
<table>
    <thead>
        <th>ФИО</th>
        <th>Дата рождения</th>
        <th>Вес</th>
        <th>Разряд</th>
        <th>Клуб</th>
        <th>Тренер</th>
        <th>Место</th>
    </thead>
    <tr *ngFor="let s of registeredList">
        <td>{{ s.sportsmen.last_name }} {{ s.sportsmen.first_name }} {{ s.sportsmen.middle_name }}</td>
        <td>{{ s.sportsmen.birthdate }}</td>
        <td>{{ s.weight}}</td>
        <td>Разряд</td>
        <td>{{ s.club }}</td>
        <td>{{ s.trainer }}</td>
        <td>{{ s.place }}</td>
    </tr>
</table>

<h4>Незарегистрированные спортсмены</h4>
<table>
    <thead>
        <tr>
            <th>ФИО</th>
            <th>Дата рождения</th>
            <th>Вес</th>
            <th>Разряд</th>
            <th>Клуб</th>
            <th>Тренер</th>
            <th>Место</th>
        </tr>
    </thead>
    <tr *ngFor="let s of unmatchedList">
        <td>{{ s.sportsmen.last_name }} {{ s.sportsmen.first_name }} {{ s.sportsmen.middle_name }}</td>
        <td>{{ s.sportsmen.birthdate }}</td>
        <td>{{ s.weight}}</td>
        <td>Разряд</td>
        <td>{{ s.club }}</td>
        <td>{{ s.trainer }}</td>
        <td>{{ s.place }}</td>
    </tr>
</table>
<div class="holder">
  <div *ngFor="let p of protocolData['protocols']" class="protocol">
    <div class="spacer"></div>
    <div class="header1">
      <div class="left logo">
        <img alt="logo" src="/assets/images/new_logo_2.svg" />
      </div>
      <div class="protocol-header">
        <div class="protocol2">ПРОТОКОЛ<br>ВЗВЕШИВАНИЯ</div>
        <div class="turnir-name">{{ protocolData.fullName }}</div>
      </div>
      <div class="right logo">
        <img alt="logo" src="/assets/images/new_logo_2.svg" />
      </div>
    </div>

    <div class="header2">
      <div>Город</div>
      <div>Года: <span><b>{{ getProtocolYears(p) }}</b></span></div>
      <div>Вес: <span><b>{{ getWeights(p) }} кг</b></span></div>
      <div>Провес: <span><b>{{ getProves(p) }} г</b></span></div>
    </div>

    <table>
      <thead>
        <th class="number">№<br/>п/п</th>
        <th>ФИО (полностью)</th>
        <th class="birthdate">Год</th>
        <th class="weight">Вес,<br>кг</th>
        <th class="location">Регион/город/клуб</th>
        <th class="razryad">Раз<br>ряд</th>
        <th class="comment">Приме<br>чание</th>
      </thead>

      <tbody>
        <tr *ngFor="let s of p['sportsmens']; let index = index;">
          <td class="number">{{ index + 1}}</td>
          <td class="name">{{ s.lastname + " " + s.firstname }}</td>
          <td class="birthdate">{{ getYear(s) }}</td>
          <td class="weight">{{ s.weight }}</td>
          <td class="location"></td>
          <td class="razryad"></td>
          <td class="comment"></td>
        </tr>
      </tbody>
    </table>

    <div class="signs">
        <div class="sign">Дата ____________________________ </div>
        <div class="sign">Главный судья _____________ / ____________________________ /</div>
        <div class="sign">Главный секретарь  _____________ / ____________________________ /</div>
    </div>
    
  </div>

  
</div>

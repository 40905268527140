import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Paginator } from 'primeng/paginator';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Turnir } from 'src/app/common/models/Turnir';
import { DatesService } from 'src/app/services/dates.service';
import { LoginService } from 'src/app/services/login.service';
import { NetworkService } from 'src/app/services/network.service';

@Component({
  selector: 'app-turnir-list',
  templateUrl: './turnir-list.component.html',
  styleUrls: ['./turnir-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TurnirListComponent implements OnInit {
  list: Array<Turnir> = [];
  totalCount: number = 0;
  cols: any[] = [];
  filters: any;
  loading = false;
  firstRowToShow = 0;

  skipOptions = [
    { name: 'Все', code: undefined },
    { name: 'Нет', code: 0 },
    { name: 'Да', code: 1 },
  ];

  publishedOptions = [
    { name: 'Все', code: undefined },
    { name: 'Нет', code: 0 },
    { name: 'Да', code: 1 },
  ];

  rowsPerPageOptions = [10, 20, 50];
  rowsPerPage = 50;
  @ViewChild('paginator') paginator!: Paginator;

  selectedProduct2: any;


  private readonly unsubscribe$: Subject<any> = new Subject<any>();

  constructor(
    private networkService: NetworkService,
    private router: Router,
    private datesService: DatesService,
    private cd: ChangeDetectorRef,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private loginService: LoginService
  ) {
    this.filters = {
      skip: 0,
      __page: 0,
      __orderBy: 'start_date',
    };
  }

  ngOnInit(): void {
    this.rowsPerPage = this.rowsPerPageOptions[this.rowsPerPageOptions.length - 1];
    
    this.route.queryParams
      .subscribe(params => {
        this.filters['__page'] = +params.__page ? +params.__page : 0;
        
        this.firstRowToShow = 0;

        if (+params.__rowsPerPage) {
          this.rowsPerPage = +params.__rowsPerPage;
        }

        if (this.filters['__page'] && this.rowsPerPage) {
          this.firstRowToShow = this.filters['__page'] * this.rowsPerPage;
        }

        if (params.name) {
          this.filters['name'] = params.name;
        }

        if (params.is_published !== null && params.is_published !== undefined) {
          this.filters['is_published'] = +params.is_published;
        }

        if (params.skip !== null && params.skip !== undefined) {
          this.filters['skip'] = +params.skip;
        }

        if (params.start_date) {
          this.filters['date'] = this.datesService.dateFromServerFormat(params.start_date);
        }

        this.loadList();
        //this.orderby = params.orderby;
        //console.log(this.orderby); // price
      }
    );
    
    this.cols = [
      { field: 'id', header: 'ID' },
      { field: 'is_published', header: 'Опубл' },
      { field: 'filesExists', header: 'Файлы' },
      { field: 'name', header: 'Название' },
      { field: 'fullname', header: 'Полное название' },
      { field: 'start_date', header: 'Дата начала' },
      { field: 'organizator', header: 'Место проведения' },
      { field: 'turnirSource', header: 'Источник турнира' },
      { field: 'actions', header: '' },
    ];
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private getQueryParams() {
    const queryParams: Params = { 
      __page: this.filters.__page,
      __rowsPerPage: this.rowsPerPage 
    };

    if (this.filters.name) {
      queryParams['name'] = this.filters.name;
    }

    if (this.filters.fullname) {
      queryParams['full_name'] = this.filters.fullname;
    }

    if (this.filters.is_published === 1 || this.filters.is_published === 0) {
      queryParams['is_published'] = this.filters.is_published;
    }

    if (this.filters.skip === 1 || this.filters.skip === 0) {
      queryParams['skip'] = this.filters.skip;
    }

    if (this.filters.date) {
      queryParams['start_date'] = this.datesService.dateToServerFormat(this.filters.date);
    }

    return queryParams;
  }

  public onPageChange(event: any) {
    this.rowsPerPage = event.rows;
    this.filters.__page = event.page;
    this.loadNewPage(this.getQueryParams());
  }

  private loadNewPage(queryParams: Params) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams,
      preserveFragment: true
      //queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }

  private getFilters() {
    const filters: any = {};
    if (this.filters.skip === 0 || this.filters.skip === 1) {
      filters.skip = this.filters.skip;
    }
    
    if (this.filters.is_published === 0 || this.filters.is_published === 1) {
      filters.is_published = this.filters.is_published;
    }

    if (this.filters.name && this.filters.name.length > 0) {
      filters.name = this.filters.name;
    }

    if (this.filters.date) {
      filters.start_date = this.datesService.dateToServerFormat(this.filters.date);
    }

    filters.__page = this.filters.__page;
    filters.__pageSize = this.rowsPerPage;
    return filters;
  }

  onFiltersChanged() {
    this.filters.__page = 0;
    this.loadNewPage(this.getQueryParams());
  }

  loadList() {
    this.loading = true;
    const filters = this.getFilters();
    this.networkService
      .getTurnirList(filters)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(resp => {
        this.loading = false;
        this.list = resp.list;
        this.list.forEach(e => {
          e.__turnir_json = (e as any)['turnir_json_new'] ? JSON.parse((e as any)['turnir_json_new']) : undefined;
        });
        this.totalCount = resp.count;
        this.cd.markForCheck();
      },
      error => {
        console.log(error)
        this.messageService.add({ key: 'main', severity: 'error', summary: error.statusText, detail: error.error ? error.error.message : "" });
      });
  }

  public onRowSelect(event: any) {
    if (this.loginService.roleCode === 1) {
      this.router.navigate(['/turnirs', event.data.id]);
    } else if (this.loginService.roleCode === 2) {
      this.router.navigate(['/turnirs', event.data.id, 'register']);
    } else {
      console.error('Role not allowed open turnir', event.data.id);
    }
  }

  // private updateCurrentPage(currentPage: number): void {
  //   // this.firstRowToShow = 3 * this.rowsPerPage - 1;
  // }

  addNew(): void {
    this.router.navigate(['/turnirs', 0]);
  }
  deleteItem(id: number) {
    if (!confirm(`Удалить турнир (ID: ${id})`)) {
      return;
    }

    this.networkService.delete('Turnir', id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(response => {
        if (response.result === null) {
          this.messageService.add({ key: 'main', severity: 'success', summary: 'Удалено', detail: 'Турнир успешно удален' });
        } else {
          this.messageService.add({ key: 'main', severity: 'error', summary: 'Ошибка', detail: response.result });
        }
        this.loadList();
        this.cd.markForCheck();
      });
  }
}

<div>Текущий статус турнира: <span><strong>{{ getCurrentTurnirStatusName() }}</strong></span></div>

<div class="formgrid grid">
    <div class="col-12 lg:col-6">
        <div>
            <div *ngIf="isRegistrationAllowed()">
                <h4>Регистрация</h4>
                <p-button (click)="onShowRegistration()" icon="pi pi-play"
                        label="Результаты регистрации">
                </p-button>
            </div>

            <h4>Взвешивание</h4>
            <div class="card flex flex-wrap gap-3">
                <p-button (click)="startWeightning()" icon="pi pi-play"
                    label="Начать взвешивание"
                    *ngIf="getCurrentTurnirStatus() != WEIGHTNING">
                </p-button>

                <p-button (click)="onOpenForm()" icon="pi pi-play"
                        label="Форма взвешивания"
                        *ngIf="getCurrentTurnirStatus() == WEIGHTNING">
                </p-button>

                <p-button (click)="stopWeightning()" icon="pi pi-stop"
                        label="Остановить взвешивание"
                        *ngIf="getCurrentTurnirStatus() == WEIGHTNING">
                </p-button>

                <p-button (click)="generateWeightningProtocols()" icon="pi pi-list"
                        label="Протоколы взвешивания"
                        *ngIf="getCurrentTurnirStatus() >= WEIGHTNING_OVER">
                </p-button>
            </div>
        </div>

        <div>
            <h4>Жеребьевка</h4>
            <div class="card flex flex-wrap gap-3">
                <p-button (click)="onGroupingStartClick()" icon="pi pi-list"
                    label="Начать распределение по группам"
                    *ngIf="getCurrentTurnirStatus() == WEIGHTNING_OVER ||
                    getCurrentTurnirStatus() == GROUPING_OVER ||
                    getCurrentTurnirStatus() == PROTOCOLS_CREATED">
                </p-button>

                <p-button (click)="onGroupingFormClick()" icon="pi pi-list"
                        label="Открыть форму распределения по группам"
                        *ngIf="getCurrentTurnirStatus() == GROUPING || getCurrentTurnirStatus() == GROUPING_OVER">
                </p-button>
            </div>
        </div>

        <div>
                <div></div>
            <h4>Создание расписания схваток</h4>
            <p-button (click)="onGenerateProtocolsClick()" icon="pi pi-list"
                    label="Сгенерировать протоколы схваток"
                    *ngIf="getCurrentTurnirStatus() == GROUPING_OVER">
            </p-button>
            <div>Создание расписания схваток</div>
            <div></div>
            <p-button (click)="openProtocols()" icon="pi pi-list"
                    label="Печать протоколов схваток"
                    *ngIf="getCurrentTurnirStatus() == GROUPING_OVER">
            </p-button>
        </div>
    </div>

    <div class="col-12 lg:col-6">
        <h4>События</h4>
        <p-table [value]="status.events" >
            <ng-template pTemplate="header">
                <tr>
                    <th>Время</th>
                    <th>Событие</th>
                </tr>
            </ng-template>
            
            <ng-template pTemplate="body" let-event>
                <tr>
                    <td>{{ event.time }}</td>
                    <td>{{ event.event }}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
<div>
  <div class="grid">
    <div class="col-12 lg:col-9">
      <div class="surface-card p-4 shadow-2 border-round p-fluid h-full">
        <div class="text-900 font-medium text-xl mb-3">Заявки</div>
        <app-applications [turnirId]="turnirId"> </app-applications>
        <!-- <div>
          <h3>Общий список из зарегистрированных заявок</h3>
          <app-registered-apps-common-list [turnirId]="turnirId"> </app-registered-apps-common-list>
        </div> -->
      </div>
    </div>
    <div class="col-12 lg:col-3">
      <div class="surface-card p-4 shadow-2 border-round p-fluid h-full">
        <div class="text-900 font-medium text-xl mb-3">Отчеты</div>
        <ul class="p-0 m-0 list-none">
          <a class="no-underline text-800" [routerLink]="[ '/report', turnirId, 'fullist' ]"
            target="_blank">
            <li class="flex align-items-center p-2 transition-all transition-duration-500 hover:surface-200">
              <div class="w-3rem h-3rem flex align-items-center justify-content-center bg-blue-100 border-circle mr-3 flex-shrink-0">
                <i class="pi pi-list text-xl text-blue-500"></i>
              </div>
              Полный список спортсменов
            </li>
          </a>

          <a class="no-underline text-800" [routerLink]="['/report', turnirId, 'preliminaryWeightningProtocols']" target="_blank">
            <li class="flex align-items-center p-2 transition-all transition-duration-500 hover:surface-200">
              <div class="w-3rem h-3rem flex align-items-center justify-content-center bg-blue-100 border-circle mr-3 flex-shrink-0">
                <i class="pi pi-file text-xl text-blue-500"></i>
              </div>
              Предварительные протоколы взвешивания (новый)
            </li>
          </a>

          <a class="no-underline text-800" [routerLink]="['/turnirs', turnirId, 'weighing']" target="_blank">
            <li class="flex align-items-center p-2 transition-all transition-duration-500 hover:surface-200">
              <div class="w-3rem h-3rem flex align-items-center justify-content-center bg-blue-100 border-circle mr-3 flex-shrink-0">
                <i class="pi pi-file text-xl text-blue-500"></i>
              </div>
              Взвешивание
            </li>
          </a>
        </ul>
      </div>
    </div>
  </div>
</div>

import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SportsmenInTurnir } from 'src/app/common/models/Organization';

@Component({
  selector: 'app-weight-sportmens-detail',
  templateUrl: './weight-sportmens-detail.component.html',
  styleUrls: ['./weight-sportmens-detail.component.scss']
})
export class WeightSportmensDetailComponent implements OnInit {
  row: SportsmenInTurnir = {} as SportsmenInTurnir;

  sexes = [
    {id: 1, name: 'Мужской'},
    {id: 0, name: 'Женский'},
  ];

  constructor(public dialogRef: DynamicDialogRef, 
              public config: DynamicDialogConfig,
              public cdr: ChangeDetectorRef) {
                
              }

  ngOnInit(): void {
    this.row = this.config.data.sit as SportsmenInTurnir;
    this.cdr.markForCheck();
  }

  onSave(): void {
    this.dialogRef.close(this.row);
  }
  onBack(): void {
    this.dialogRef.close();
  }
  
  isFormValid(): boolean {
    return !!this.row.firstname && !!this.row.lastname;
  }

}

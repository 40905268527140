import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators';
import { NetworkService } from './network.service';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private static ROLE_ADMIN = 1;
  private static ROLE_ORGANIZER = 2;
  
  private sourcePath = '/';
  _state = false;
  public stateSubject = new BehaviorSubject(this.state);
  isLoading = new BehaviorSubject<boolean>(false);
  public roleCode = 0;

  set state(value) {
    this._state = value;
    this.stateSubject.next(this._state);
  }

  get state() {
    return this._state;
  }

  constructor(private networkService: NetworkService, private router: Router, private location: Location) {}

  login(login: string, password: string) {
    this.isLoading.next(true);
    return this.networkService
      .login(login, password)
      .pipe(
        switchMap(() => {
          return this.testSession();
        }),
        map(data => {
          //this.state = true;
          // this.cachedService.init().subscribe();
          this.router.navigate([this.sourcePath]);
          this.sourcePath = '';
        }),
        catchError((error, source) => {
          this.state = false;
          return throwError(error);
        }),
        finalize(() => this.isLoading.next(false)),
      )
      .subscribe();
  }

  logout() {
    this.isLoading.next(true);
    this.networkService.logout().pipe(
      finalize(() => this.isLoading.next(false)),
    ).subscribe(() => {
      this.state = false;
      this.router.navigate(['/', 'login']);
    });
  }

  paramsToObject(searchParams: any) {
    const result:any = {}
    
  for(const key of searchParams.keys()) { // each 'entry' is a [key, value] tupple
    result[key] = searchParams.get(key);
  }
  return result;
  }

  testSession(): Observable<any> {
    this.sourcePath = this.location.path();

    return this.networkService.testSession().pipe(
      tap(data => {
        console.info('testSession data', data);
        this.state = true;
        const url = this.sourcePath.split('?')[0];
        const search = this.sourcePath.split('?')[1];
        const obj = new URLSearchParams('?' + search);
        this.router.navigate([url], {queryParams: this.paramsToObject(obj)});
        this.sourcePath = '';
        this.roleCode = data.result.roleCode
      }),
      catchError((error, source) => {
        this.state = false;
        return throwError(error);
      }),
    );
  }

  public isOrganizer() {
    return this.roleCode === LoginService.ROLE_ORGANIZER || this.roleCode === LoginService.ROLE_ADMIN;
  }
}

<div>
  <div class="surface-card p-4 shadow-2 border-round p-fluid">

    <div class="grid formgrid p-fluid">
      <div class="field mb-4 col-12 md:col-6 lg:col-4">
        <label for="name" class="font-medium text-900">Название 
            <span class="label-info">({{ (row.name && row.name) ? row.name.length : 0 }} из 40 символов) </span>
        </label>
        <input id="name" type="text" pinputtext="" class="p-inputtext p-component p-element" 
          [(ngModel)]="row.name" required  />
      </div>

      <div class="field mb-4 col-12 md:col-6 lg:col-4">
        <label for="sportType" class="font-medium text-900">Вид спорта</label>

        <p-dropdown
          [options]="sportOptions"
          [(ngModel)]="row.sport_id"
          optionLabel="name"
          optionValue="id"
          [filter]="true"
          filterBy="name"
          [showClear]="true"
          placeholder="Выберите вид спорта"
        >
        </p-dropdown>
      </div>

      <div class="field mb-4 col-12 md:col-6 lg:col-4">
        <label for="turnirType" class="font-medium text-900">Тип турнира</label>
        <p-dropdown
          [options]="turnirTypeOptions"
          [(ngModel)]="row.turnir_type_id"
          optionLabel="name"
          optionValue="id"
          [filter]="true"
          filterBy="name"
          [showClear]="true"
          placeholder="Выберите тип турнира"
        >
        </p-dropdown>
      </div>

      <div class="field mb-4 col-12">
        <label for="fullName" class="font-medium text-900">Полное название
          <span class="label-info">({{ (row.fullname && row.fullname) ? row.fullname.length : 0 }} из 300 символов) </span>
        </label>
        <input id="fullName" type="text" [(ngModel)]="row.fullname" pinputtext="" class="p-inputtext p-component p-element" />
      </div>

      <div class="field mb-4 col-12 md:col-6 lg:col-4">
        <label for="turnirType" class="font-medium text-900">Организатор</label>
        <p-dropdown
          [options]="organizationOptions"
          [(ngModel)]="row.organizator_id"
          optionLabel="name"
          optionValue="id"
          [filter]="true"
          filterBy="id,name,fullname"
          [showClear]="true"
          placeholder="Выберите организатора турнира"
        >
        </p-dropdown>
      </div>

      <div class="field mb-4 col-12 md:col-6 lg:col-4">
        <label for="turnirType" class="font-medium text-900">Место проведения</label>
        <p-dropdown
          [options]="organizationOptions"
          [(ngModel)]="row.place_organization_id"
          optionLabel="name"
          optionValue="id"
          [filter]="true"
          filterBy="id,name,fullname"
          [showClear]="true"
          placeholder="Выберите место проведения">
        </p-dropdown>
      </div>

      <div class="field mb-4 col-12 md:col-6 lg:col-4">
        <label for="ekp" class="font-medium text-900">ЕКП/КП</label>
        <input id="ekp" type="text" pinputtext="" class="p-inputtext p-component p-element" [(ngModel)]="row.options.ekp" />
      </div>

      <div class="field mb-4 col-12 md:col-3 lg:col-3">
        <label for="start_date" class="font-medium text-900">Дата начала</label>
        <p-calendar
          id="start_date"
          class="p-element p-inputwrapper ng-tns-c38-4 ng-star-inserted"
          [(ngModel)]="row.start_date"
          [showClear]="true"
          [maxDate]="getStopDate()"
        >
          <span class="ng-tns-c38-4 p-calendar p-calendar-w-btn">
            <input type="text" autocomplete="off" class="ng-tns-c38-4 p-inputtext p-component ng-star-inserted" placeholder="" />
            <button
              type="button"
              pbutton=""
              pripple=""
              tabindex="0"
              class="p-element p-ripple p-datepicker-trigger ng-tns-c38-4 p-button p-component p-button-icon-only ng-star-inserted"
            >
              <span class="p-button-icon pi pi-calendar" aria-hidden="true"></span>
              <span aria-hidden="true" class="p-button-label">&nbsp;</span>
              <span class="p-ink"></span>
            </button>
          </span>
        </p-calendar>
      </div>

      <div class="field mb-4 col-12 md:col-3 lg:col-3">
        <label for="end_date" class="font-medium text-900">Дата окончания</label>
        <p-calendar
          id="end_date"
          class="p-element p-inputwrapper ng-tns-c38-4 ng-star-inserted"
          [(ngModel)]="row.end_date"
          [showClear]="true"
          [minDate]="getStartDate()"
        >
          <span class="ng-tns-c38-4 p-calendar p-calendar-w-btn">
            <input type="text" autocomplete="off" class="ng-tns-c38-4 p-inputtext p-component ng-star-inserted" placeholder="" />
            <button
              type="button"
              pbutton=""
              pripple=""
              tabindex="0"
              class="p-element p-ripple p-datepicker-trigger ng-tns-c38-4 p-button p-component p-button-icon-only ng-star-inserted"
            >
              <span class="p-button-icon pi pi-calendar" aria-hidden="true"></span>
              <span aria-hidden="true" class="p-button-label">&nbsp;</span>
              <span class="p-ink"></span>
            </button>
          </span>
        </p-calendar>
      </div>

      <div class="field col-12 md:col-3 lg:col-3">
        <label for="startPay" class="font-medium text-900">Стартовый взнос</label>
        <input id="startPay" type="text" pinputtext="" class="p-inputtext p-component p-element" [(ngModel)]="row.options.startPay" />
      </div>

      <div class="field col-12 md:col-3 lg:col-2 flex align-items-center pt-3">
        <p-checkbox class="p-element" [(ngModel)]="row.status_parse" [binary]="true"></p-checkbox>
        <span class="ml-2 text-900">Парсинг необработан</span>
      </div>

      <div class="field col-12 md:col-3 lg:col-2 flex align-items-center pt-3">
        <p-checkbox class="p-element" [(ngModel)]="row.cancelled" [binary]="true"></p-checkbox>
        <span class="ml-2 text-900">Турнир отменен</span>
      </div>

      <div class="field col-12 md:col-3 lg:col-2 flex align-items-center pt-3">
        <p-checkbox class="p-element" [(ngModel)]="row.options.postponed" [binary]="true"></p-checkbox>
        <span class="ml-2 text-900">Турнир перенсен</span>
      </div>

      <div class="field mb-4 col-12 md:col-3 lg:col-4">
        <label for="postponedDate" class="font-medium text-900">Дата переноса</label>
        <p-calendar
          id="postponedDate"
          class="p-element p-inputwrapper ng-tns-c38-4 ng-star-inserted"
          [(ngModel)]="row.options.postponedDate">
          <span class="ng-tns-c38-4 p-calendar p-calendar-w-btn">
            <input type="text" autocomplete="off" class="ng-tns-c38-4 p-inputtext p-component ng-star-inserted" placeholder="" />
            <button
              type="button"
              pbutton=""
              pripple=""
              tabindex="0"
              class="p-element p-ripple p-datepicker-trigger ng-tns-c38-4 p-button p-component p-button-icon-only ng-star-inserted"
            >
              <span class="p-button-icon pi pi-calendar" aria-hidden="true"></span>
              <span aria-hidden="true" class="p-button-label">&nbsp;</span>
              <span class="p-ink"></span>
            </button>
          </span>
        </p-calendar>
      </div>

      <div class="mb-4 col-12">
        <h3>Возраста и веса</h3>
        <div class="grid formgrid p-fluid">
          <div class="field col-12 md:col-4 lg:col-3">
            <button pButton pRipple type="button" label="Добавить" icon="pi pi-plus" 
              class="h-3rem" (click)="onAddWeight()"></button>
          </div>

          <div class="field col-12 md:col-4 lg:col-3">
            <label for="registration_proves" class="font-medium text-900">Провес, грамм</label>
            <input id="registration_proves"
              type="text"
              pinputtext=""
              class="p-inputtext p-component p-element"
              [(ngModel)]="row.options.proves"
            />
          </div>

          <div class="field col-12 md:col-4 lg:col-3">
            <label for="registration_max_sportsmens" class="font-medium text-900">Max кол-во участников</label>
            <input id="registration_max_sportsmens"
              type="text"
              pinputtext=""
              class="p-inputtext p-component p-element"
              [(ngModel)]="row.options.max_sportsmens"
            />
          </div>
        </div>

        <div class="grid formgrid p-fluid" *ngFor="let el of row.options.weights; let ind = index">
          <div class="col-4 lg:col-2">
            <p-dropdown [options]="sexOptions" [(ngModel)]="el.sex" optionLabel="name" optionValue="id" placeholder="Выберите пол">
            </p-dropdown>
          </div>

          <div class="col-4 lg:col-2 mb-4">
            <input type="text" pinputtext="" class="p-inputtext p-component p-element" 
            placeholder="Года" [(ngModel)]="el.years" />
          </div>

          

          <div class="p-inputgroup col-12 lg:col-4 mb-4">
            <input type="text" pInputText placeholder="Веса" [(ngModel)]="el.weights">   
            <button type="button" pButton pRipple icon="pi pi-refresh" styleClass="p-button-warn"
              (click)="onNormalizeWeight(el)"></button>      
          </div>

          <div class="col-4 lg:col-2">
            <input type="text" pinputtext="" class="p-inputtext p-component p-element mb-4"
              placeholder="Провес"
              [(ngModel)]="el.proves" />
          </div>

          <div class="col-4 lg:col-2">
            <div class="flex align-content-center">
            <input type="text" pinputtext="" class="p-inputtext p-component p-element mb-4"
              placeholder="Max спортсменов"
              [(ngModel)]="el.max_sportsmens" />
              <button
                pButton
                pRipple
                type="button"
                icon="pi pi-trash"
                class="p-button-rounded p-button-danger p-button-text ml-2"
                (click)="onDeleteAge(ind)"
              ></button>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-4 col-12">
        <h3>Регистрация (предварительная заявка)</h3>
        <div class="formgrid grid">
          <div class="col-12 md:col-6 lg:col-3">
            <div  class="formgrid grid">
              <div class="field col-12">
                <p-checkbox class="p-element" [(ngModel)]="row.options.registration.required" [binary]="true"></p-checkbox>
                <span class="ml-2 text-900">Требуется регистрация</span>
              </div>
            </div>

            <div class="field col-12">
              <label for="registration_end_date" class="font-medium text-900">Дата завершения регистрации</label>
              <p-calendar
                id="registration_end_date"
                class="p-element p-inputwrapper ng-tns-c38-4 ng-star-inserted"
                [(ngModel)]="row.options.registration.endDate"
                [maxDate]="getStartDate()"
              >
                <span class="ng-tns-c38-4 p-calendar p-calendar-w-btn">
                  <input type="text" autocomplete="off" class="ng-tns-c38-4 p-inputtext p-component ng-star-inserted" placeholder="" />
                  <button
                    type="button"
                    pbutton=""
                    pripple=""
                    tabindex="0"
                    class="p-element p-ripple p-datepicker-trigger ng-tns-c38-4 p-button p-component p-button-icon-only ng-star-inserted"
                  >
                    <span class="p-button-icon pi pi-calendar" aria-hidden="true"></span>
                    <span aria-hidden="true" class="p-button-label">&nbsp;</span>
                    <span class="p-ink"></span>
                  </button>
                </span>
              </p-calendar>
            </div>

          </div>
          
          
          <div class="col-12 md:col-6 lg:col-3">
            <div class="formgrid grid">
              <div class="field col-12">
                <p-checkbox class="p-element" [(ngModel)]="row.options.registration.enabled" [binary]="true"></p-checkbox>
                <span class="ml-2 text-900">Регистрация через нас</span>
              </div>

              <div class="field col-12">
                <p-checkbox class="p-element" [(ngModel)]="row.options.registration.stopped" [binary]="true"></p-checkbox>
                <span class="ml-2 text-900">Временно приостановлена</span>
              </div>
            </div>
          </div>

          <div class="col-12 lg:col-3">
            <div class="formgrid grid">
              <div class="field col-12">
                <div class="field col-12" *ngIf="loginService.isOrganizer()">
                  <button pButton pRipple type="button" label="Проведение турнира" icon="pi pi-play" 
                    class="h-3rem p-button-success" (click)="onTurnirOrganizeClick()"></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-4 col-12 md:col-4">
        <h3>Продвижение турнира</h3>
        <div class="formgrid grid">
          <div class="field col-12">
            <p-checkbox class="p-element" [(ngModel)]="row.options.decor.color" [binary]="true"></p-checkbox>
            <span class="ml-2 text-900">Выделить цветом</span>
          </div>

          <div class="field col-12">
            <p-checkbox class="p-element" [(ngModel)]="row.options.partner.on" [binary]="true"></p-checkbox>
            <span class="ml-2 text-900">Партнерский турнир</span>
          </div>
        </div>
      </div>

      <div class="mb-4 col-12 md:col-8">
        <div class="formgrid grid">
          <div class="col-12">
            <label for="file_poster" class="font-medium text-900">Описание</label>
            <!-- <ckeditor [(ngModel)]="row.options.description" name="description" class="p-inputtext p-component p-element"></ckeditor> -->
            <ckeditor [editor]="Editor" [(ngModel)]="row.options.description" name="description" class="p-inputtext p-component p-element"></ckeditor>
        
          </div>
        </div>
      </div>

      <!-- TODO: <div class="col-12">
                <h3>Ограничения на турнире</h3>
                <div>limitations</div>
                <div>warnings</div>
            </div> -->

      <div class="col-12">
        <h3>Файлы</h3>

        <div class="formgrid grid">
          <div class="col-12 lg:col-6">
            <div class="formgrid grid">
              <div class="field col-6">
                <div class="mb-2">Загрузка положения</div>
                <p-fileUpload
                  #fubauto
                  mode="basic"
                  (onSelect)="onUploadFileClick($event, 'POLOZHENIE')"
                  [auto]="true"
                  chooseLabel="Выбрать..."
                ></p-fileUpload>
              </div>
              
              <div class="field col-6">
                <label for="registration.email" class="font-medium text-900">Путь к файлу положения</label>
                <input
                  id="registration.email"
                  type="text"
                  pinputtext=""
                  class="p-inputtext p-component p-element"
                  [(ngModel)]="row.polozhenie_file_name"
                />
              </div>
            </div>
            
            <div class="formgrid grid">
              <div class="field col-6">
                <div class="mb-2">Загрузка регламента</div>
                <p-fileUpload #fubautoReglament
                  mode="basic"
                  (onSelect)="onUploadFileClick($event, 'REGLAMENT')"
                  [auto]="true"
                  chooseLabel="Выбрать..."
                ></p-fileUpload>
              </div>
              <div class="field col-6">
                <label for="file_reglament" class="font-medium text-900">Путь к файлу регламента</label>
                <input
                  id="file_reglament"
                  type="text"
                  pinputtext=""
                  class="p-inputtext p-component p-element"
                  [(ngModel)]="row.options.files.reglament"
                />
              </div>
            </div>
          </div>

          <div class="col-12 lg:col-6">
            <div class="formgrid grid">
              <div class="field col-6">
                <div class="mb-2">Загрузка афишы</div>
                <p-fileUpload
                  #fubauto1
                  mode="basic"
                  (onSelect)="onUploadFileClick($event, 'POSTER')"
                  [auto]="true"
                  chooseLabel="Выбрать..."
                ></p-fileUpload>
              </div>
              <div class="field col-6">
                <label for="file_poster" class="font-medium text-900">Путь к файлу афишы</label>
                <input
                  id="file_poster"
                  type="text"
                  pinputtext=""
                  class="p-inputtext p-component p-element"
                  [(ngModel)]="row.options.files.poster"
                />
              </div>
            </div>

            <div class="formgrid grid">
              <div class="field col-6">
                <div class="mb-2">Загрузка промоматериала</div>
                <p-fileUpload
                  #fubautoPromo
                  mode="basic"
                  (onSelect)="onUploadFileClick($event, 'PROMO')"
                  [auto]="true"
                  chooseLabel="Выбрать..."
                ></p-fileUpload>
              </div>
              <div class="field col-6">
                <label for="file_poster" class="font-medium text-900">Путь к файлу промоматериала</label>
                <input
                  id="file_poster"
                  type="text"
                  pinputtext=""
                  class="p-inputtext p-component p-element"
                  [(ngModel)]="row.options.files.promo"
                />
              </div>
            </div>
          </div>
        </div>
        

        <div class="col-12">
          <div class="col-md-12 my-4" *ngIf="row.turnir_json_new && row.turnir_json_new.__">
            <div>
              Исходный url: <a [attr.href]="row.turnir_json_new.__.link" target="_blank">{{ row.turnir_json_new.__.link }} </a>
            </div>
          </div>
        </div>

        <div class="col-12" *ngIf="row && row.turnir_json_new && row.turnir_json_new.files">
          <h4>Файлы для парсинга:</h4>

          <div *ngFor="let f of row.turnir_json_new.files">
            <p>
              {{ f.label }}: <a [attr.href]="f.url">Ссылка</a>
            </p>
            
            <p class="download-block-buttons">
              <button pbutton="" pripple=""
                label="Скачать как положение"
                icon="pi pi-file"
                class="p-element p-ripple w-auto p-button p-component"
                (click)="onUploadTurnirFromRemote(f, 'POLOZHENIE')">
                <span class="p-button-icon p-button-icon-left pi pi-file" aria-hidden="true"></span>
                <span class="p-button-label">Скачать как положение</span>
                <span class="p-ink"></span>
              </button>

              <button pbutton="" pripple=""
                label="Скачать как положение"
                icon="pi pi-file"
                class="p-element p-ripple w-auto p-button p-component"
                (click)="onUploadTurnirFromRemote(f, 'REGLAMENT')">
                <span class="p-button-icon p-button-icon-left pi pi-file" aria-hidden="true"></span>
                <span class="p-button-label">Скачать как регламент</span>
                <span class="p-ink"></span>
              </button>
            </p>  
          </div>
        </div>

        <div class="col-12" *ngIf="row && row.turnir_json_new && row.turnir_json_new.files_as_images">
          <div>Положение в картинках</div>
          <div class="flex">
            <div *ngFor="let el of row.turnir_json_new.files_as_images">
              <a [attr.href]="el">
                <img [src]="el" style="width: 150px;" />
              </a>
            </div>
          </div>
          <button
            pbutton=""
            pripple=""
            label="Скачать, преобразовать в pdf и сохранить"
            icon="pi pi-file"
            class="p-element p-ripple w-auto p-button p-component"
            (click)="onDownloadAndConvertClick()"
          >
            <span class="p-button-icon p-button-icon-left pi pi-file" aria-hidden="true"></span>
            <span class="p-button-label">Скачать, преобразовать в pdf и сохранить</span>
            <span class="p-ink"></span>
          </button>
        </div>
      </div>

      <div class="col-12 flex">
        <pre> {{ row.turnir_json | json }} </pre>

        <pre> {{ row.turnir_json_new | json }} </pre>
      </div>
    </div>

    <div class="formgrid grid">
      <div class="alert col-12" *ngIf="!isFormValid()">
        <div *ngFor="let e of errors">
          {{ e }}
        </div>
      </div>

      <div class="field col-12 md:col-3 lg:col-2 flex align-items-center pt-3">
        <button
          [disabled]="!isFormValid()"
          pbutton=""
          pripple=""
          label="Сохранить"
          icon="pi pi-file"
          class="p-element p-ripple w-auto p-button p-component"
          (click)="onSave()">
          <span class="p-button-icon p-button-icon-left pi pi-file" aria-hidden="true"></span>
          <span class="p-button-label">Сохранить</span>
          <span class="p-ink"></span>
        </button>
      </div>

      <div class="field col-12 md:col-3 lg:col-2 flex align-items-center pt-3">
        <p-checkbox class="p-element" [(ngModel)]="row.is_published" [binary]="true"></p-checkbox>
        <span class="ml-2 text-900">Опубликован</span>
      </div>

      <div class="field col-12 md:col-3 lg:col-2 flex align-items-center pt-3">
        <p-checkbox class="p-element" [(ngModel)]="row.skip" [binary]="true"></p-checkbox>
        <span class="ml-2 text-900">Пропускать в админке</span>
      </div>
    </div>
  </div>
</div>

import { Injectable } from "@angular/core";
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from "@angular/router";
import {Observable} from "rxjs";
import { LoginService } from "src/app/services/login.service";
 
@Injectable()
export class TurnirGuard implements CanActivate {
    constructor(private loginService: LoginService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : Observable<boolean> | boolean{
        return this.loginService.roleCode === 1 || this.loginService.roleCode === 2;
    }
}

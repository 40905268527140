<!-- <div class="load-overlay" *ngIf="loading"><p-progressSpinner></p-progressSpinner></div> -->
<p-card class="position-relative">
  <form #form>
    <div class="card">
      <div class="formgrid grid">
        <div class="field col-12 md:col-4">
          <label for="lastname" class="font-medium text-900">Фамилия (*)</label>
          <input
            #lastname="ngModel"
            id="lastname"
            name="lastname"
            type="text"
            required
            class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
            [(ngModel)]="row.lastname"
            pAutoFocus
            [autofocus]="true"
          />

          <div *ngIf="lastname.invalid && (lastname.dirty || lastname.touched)" class="alert">
            <div *ngIf="lastname.errors?.['required']">
              Это поле обязательно для заполнения
            </div>
          </div>
        </div>
        <div class="field col-12 md:col-4">
          <label for="firstname" class="font-medium text-900">Имя (*)</label>
          <input
            #firstname="ngModel"
            id="firstname"
            name="firstname"
            type="text"
            required
            class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
            [(ngModel)]="row.firstname"
          />
          <div *ngIf="firstname.invalid && (firstname.dirty || firstname.touched)" class="alert">
            <div *ngIf="firstname.errors?.['required']">
              Это поле обязательно для заполнения
            </div>
          </div>
        </div>

        <div class="field col-12 md:col-4">
          <label for="middlename" class="font-medium text-900">Отчество</label>
          <input
            #middlename="ngModel"
            id="middlename"
            name="middlename"
            type="text"
            class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
            [(ngModel)]="row.middlename"
          />
          <!-- <div *ngIf="middlename.invalid && (middlename.dirty || middlename.touched)" class="alert">
                                <div *ngIf="middlename.errors?.['required']">
                                Это поле обязательно для заполнения
                                </div>
                        </div> -->
        </div>

        <div class="field mb-4 col-12 md:col-4">
          <label for="birthdate" class="font-medium text-900">Дата рождения (*)</label>
          <p-calendar
            #birthdate="ngModel"
            id="birthdate"
            name="birthdate"
            appendTo="body"
            class="p-element p-inputwrapper ng-tns-c38-4 ng-star-inserted w-full"
            [(ngModel)]="row.birthdate"
            [required]="true"
            dateFormat="yy-mm-dd"
            dataType="string"
          >
            <span class="ng-tns-c38-4 p-calendar p-calendar-w-btn">
              <input type="text" autocomplete="off" class="ng-tns-c38-4 p-inputtext p-component ng-star-inserted" placeholder="" />
              <button
                type="button"
                pbutton=""
                pripple=""
                tabindex="0"
                class="p-element p-ripple p-datepicker-trigger ng-tns-c38-4 p-button p-component p-button-icon-only ng-star-inserted"
              >
                <span class="p-button-icon pi pi-calendar" aria-hidden="true"></span>
                <span aria-hidden="true" class="p-button-label">&nbsp;</span>
                <span class="p-ink"></span>
              </button>
            </span>
          </p-calendar>

          <div *ngIf="birthdate.invalid && (birthdate.dirty || birthdate.touched)" class="alert">
            <div *ngIf="birthdate.errors?.['required']">
              Это поле обязательно для заполнения
            </div>
          </div>
        </div>

        <div class="field col-12 md:col-4">
          <label for="weight" class="font-medium text-900">Вес, кг (*)</label>
          <p-inputNumber [minFractionDigits]="2"
            #weight="ngModel"
            id="weight"
            name="weight"
            [required]="true"
            [(ngModel)]="row.weight"
            class="w-full">
          </p-inputNumber>

          <div *ngIf="weight.invalid && (weight.dirty || weight.touched)" class="alert">
            <div *ngIf="weight.errors?.['required']">
              Это поле обязательно для заполнения
            </div>
          </div>
        </div>

        <div class="field col-12 md:col-4">
          <label for="weightInApplication" class="font-medium text-900">Пол</label>
          <p-dropdown [options]="sexes" [(ngModel)]="row.sex"
            optionLabel="name"
            optionValue="id"
            id="sex"
            name="sex"
            class="w-full">
          </p-dropdown>
        </div>

        <div class="field col-12 md:col-4">
          <label for="weightInApplication" class="font-medium text-900">Вес в заявке, кг</label>
          <input
            #weightInApplication="ngModel"
            id="weightInApplication"
            name="weightInApplication"
            type="text"
            class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
            [(ngModel)]="row.weightInApplication"
          />
        </div>

        <div class="field col-12 md:col-4">
          <label for="documentType" class="font-medium text-900">Тип документа</label>
          <input
            #documentType="ngModel"
            id="documentType"
            name="documentType"
            type="text"
            class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
            [(ngModel)]="row.documentType"
          />
        </div>

        <div class="field col-12 md:col-4">
          <label for="documentNumber" class="font-medium text-900">Серия/номер документа</label>
          <input
            #documentNumber="ngModel"
            id="documentNumber"
            name="documentNumber"
            type="text"
            class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
            [(ngModel)]="row.documentNumber"
          />
        </div>

        <div class="field col-12 md:col-4">
          <label for="club" class="font-medium text-900">Клуб/Город/Регион</label>
          <input
            #club="ngModel"
            id="club"
            name="club"
            type="text"
            class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
            [(ngModel)]="row.club"
          />
        </div>

        <div class="field col-12 md:col-4">
          <label for="trainer" class="font-medium text-900">Тренер</label>
          <input
            #trainer="ngModel"
            id="trainer"
            name="trainer"
            type="text"
            class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
            [(ngModel)]="row.trainer"
          />
        </div>
      </div>

      <!-- <div class="field">
                <label for="middlename">Отчество</label>
                <input
                  id="middlename"
                  name="middlename"
                  type="text"
                  class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                  [(ngModel)]="row.middlename"
                />
              </div> -->
    </div>
  </form>
  <ng-template pTemplate="footer">
    <p-button label="Отмена" styleClass="p-button-link" (click)="onBack()"></p-button>
    <p-button
      class="w-auto"
      label="Сохранить"
      icon="pi pi-file"
      iconPos="right"
      (onClick)="onSave()"
      [disabled]="!isFormValid()"
      type="submit"
    ></p-button>
  </ng-template>
</p-card>

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GroupingComponent } from './grouping/grouping.component';
import { RegisterComponent } from './register/register/register.component';
import { TurnirDetailComponent } from './turnir-detail/turnir-detail.component';
import { TurnirGuard } from './turnir-detail/turnir.guard';
import { TurnirListComponent } from './turnir-list/turnir-list.component';
import { WeighingComponent } from './weighing/weighing.component';
import { TurnirOrganizerComponent } from './turnir-organizer/turnir-organizer.component';
import { WeightProtocolsComponent } from './weighing/weight-protocols/weight-protocols.component';
import { ProtocolsComponent } from '../report/components/protocols/protocols.component';

const routes: Routes = [
  {
    path: '',
    component: TurnirListComponent,
  },
  {
    path: ':turnirid',
    component: TurnirDetailComponent,
    canActivate: [TurnirGuard]
  },
  {
    path: ':turnirid/register',
    component: RegisterComponent,
  },
  {
    path: ':turnirid/weighing',
    component: WeighingComponent,
  },
  {
    path: ':turnirid/organize',
    component: TurnirOrganizerComponent,
    canActivate: [TurnirGuard]
  },
  {
    path: ':turnirid/grouping',
    component: GroupingComponent,
  },
  {
    path: ':turnirid/protocols',
    component: ProtocolsComponent,
  },
  { path: '**', redirectTo: 'turnirs' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TurnirRoutingModule {}

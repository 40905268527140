import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-stat',
  templateUrl: './stat.component.html',
  styleUrls: ['./stat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatComponent implements OnInit {

  @Input() label: string = '';
  @Input() value: number = 0;
  @Input() icon: string = '';
  @Input() color: string = '';

  constructor() { }

  ngOnInit(): void {
  }

}

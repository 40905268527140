import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SportsmenInTurnir } from 'src/app/common/models/Organization';

@Component({
  selector: 'app-weight-sportmens-list',
  templateUrl: './weight-sportmens-list.component.html',
  styleUrls: ['./weight-sportmens-list.component.scss']
})
export class WeightSportmensListComponent implements OnInit {
  cols: any[] = [];
  @Input()
  list: SportsmenInTurnir[] = []; // TODO: strong type
  
  @Output() onRowSelect = new EventEmitter();
  @Output() onRowDelete:EventEmitter<SportsmenInTurnir> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    this.cols = [
      // { field: 'selected', header: '' },
      { field: 'index', header: '№' },
      { field: 'name', header: 'ФИО', sortableColumn: 'name', sortableField: 'lastname' },
      { field: 'birthdate', header: 'Дата рождения' },
      { field: 'weight', header: 'Вес', sortableField: 'weight' },
      { field: 'actions', header: '' },
    ];
  }

  public onRowSelectHandler(event: any) {
    this.onRowSelect.emit(event.data);
  }

  deleteItem(sit: SportsmenInTurnir) {
    this.onRowDelete.emit(sit);
  }
}

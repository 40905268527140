import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { ConfirmationService, MessageService, PrimeNGConfig } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppStateService } from './services/app-state.service';
import { LoginService } from './services/login.service';
import { NetworkService } from './services/network.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MessageService, ConfirmationService],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Turnir management';
  loggedIn = false;
  sidebarWidth = 0;
  loading = false;
  reportMode = false;

  private readonly unsubscribe$: Subject<any> = new Subject<any>();

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (window.innerWidth <= 960) {
      this.appStateService.sidebarWidth.next(0);
    } else {
      this.appStateService.sidebarWidth.next(300);
    }
  }

  constructor(
    private loginService: LoginService,
    private cdr: ChangeDetectorRef,
    private appStateService: AppStateService,
    private networkService: NetworkService,
    private route: ActivatedRoute,
    private primengConfig: PrimeNGConfig
  ) {
    this.loginService.stateSubject.pipe(takeUntil(this.unsubscribe$)).subscribe(state => {
      this.loggedIn = state;
      this.cdr.markForCheck();
    });

    this.route.paramMap.pipe(takeUntil(this.unsubscribe$)).subscribe(paramMap => {
      // this.id = Number(paramMap.get('id'));

      // if (this.id) {
      //   this.loadReviewData();
      // }
      console.log('ffffff', location.pathname);

      this.reportMode = location.pathname.startsWith('/report/');
      
    });
  }

  ngOnInit(): void {
    if (window.innerWidth <= 960) {
      this.appStateService.sidebarWidth.next(0);
    }
    this.appStateService.sidebarWidth.pipe(takeUntil(this.unsubscribe$)).subscribe(val => {
      this.sidebarWidth = val;
      this.cdr.markForCheck();
    });
    this.loginService.isLoading.pipe(takeUntil(this.unsubscribe$)).subscribe(state => {
      this.loading = state;
    });
    this.networkService.isLoading.pipe(takeUntil(this.unsubscribe$)).subscribe(state => {
      this.loading = state;
    });

    this.primengConfig.setTranslation({
      dateFormat: "yy-mm-dd",
      firstDayOfWeek: 1,
      dayNamesMin:	["Вс","Пн","Вт","Ср","Чт","Пт","Сб"],
      monthNames:	["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь",
        "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"]
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

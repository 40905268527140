<div>
  <div
    *ngIf="getUnmatchedCount()"
    class="bg-red-500 text-gray-100 p-3 flex justify-content-center align-items-center flex-wrap border-round-sm mb-3"
  >
    <div>
      Нераспределенные спортсмены: <span class="font-bold">{{ getUnmatchedCount() }}</span>
    </div>
  </div>

  <div class="grid mb-5">
    <div class="col-12 lg:col-6" *ngFor="let age of data['ages']">
      <div class="surface-card shadow-2 border-round p-4 h-full">
        <div class="flex pb-3 mb-3 border-bottom-1 surface-border">
          <div class="flex align-items-center justify-content-center bg-blue-100 border-round mr-3" style="width:3rem;height:3rem">
            <i class="pi pi-hashtag text-blue-500 text-xl"></i>
          </div>
          <div>
            <div class="flex align-items-center justify-content-between mb-1">
              <span class="text-xl font-medium text-900">Возраст:  {{ getYear(data['startDate']) - age["maxYear"] }} - {{ getYear(data['startDate']) - age["minYear"] }} ({{ age["name"] }})
                {{ age['sex'] === 'm' ?  'Мужской' : 'Женский' }}
              </span>
            </div>
            <div class="flex align-items-center justify-content-between ">
              <span class="text-600">Всего спортсменов по возрасту: {{ getAgeSportsmensCount(age) }}</span>
            </div>
          </div>
        </div>

        <div class="flex align-items-center justify-content-between mb-2 font-bold">Разбивка по весам:</div>

        <ul class="mt-3 list-none p-0 mx-0 mb-0">
          <li class="flex align-items-center pb-3" *ngFor="let w of age['weights']">
            <span class="border-round bg-blue-500 mr-3 flex-shrink-0" style="width: 1rem; height: 1rem;"></span>
            <span class="text-md font-medium text-90">{{ w["name"] }} ({{ w["min"] }}-{{ w["max"] }})</span>
            <span class="text-600 text-md font-medium ml-auto">{{ w["sportsmens"].length }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="grid">
  <div class="col-12 lg:col-6">
    <app-stat label="Всего заявок" [value]="list.length" icon="pi-file" color="green"></app-stat>
  </div>
  <div class="col-12 lg:col-6">
    <app-stat label="Всего спортсменов" [value]="countSportsmens()" icon="pi-user" color="purple"></app-stat>
  </div>
</div>

<div>
  <h4>Ожидающие подтверждения ({{ registered.length }})</h4>
  <p-dataView [value]="registered" [paginator]="true" [rows]="6" emptyMessage="Зарегистрированных заявок нет">
    <ng-template let-app pTemplate="listItem">
      <div class="col-12 md:col-4 mb-3">
        <p-card [header]="'Клуб: ' + app.club" [subheader]="'Тренер: ' + app.trainer" styleClass="p-card-shadow mr-3 h-full">
          <ng-template pTemplate="header">
            <div class="bg-blue-300 w-full h-1rem"></div>
          </ng-template>
          <div class="mb-1">Представитель: {{ app.predstavitel }}</div>
          <div class="mb-1">Кол-во спортсменов: {{ app.sportsmens.length }}</div>
          <ng-template pTemplate="footer">
            <div class="flex justify-content-start">
              <button pButton pRipple type="button" label="Подтвердить" icon="pi pi-check" class="w-6 mr-2 p-button-outlined p-button-success" (click)="onConfirm(app['id'])"></button>
              <button pButton pRipple type="button" label="Отклонить" icon="pi pi-check" class="w-6 p-button-outlined p-button-danger" (click)="onReject(app['id'])"></button>
            </div>
          </ng-template>
        </p-card>
      </div>
    </ng-template>
  </p-dataView>

  <h4>Подтвержденные ({{ confirmed.length }})</h4>
  <p-dataView [value]="confirmed" [paginator]="true" [rows]="6" emptyMessage="Подтвержденных заявок нет">
    <ng-template let-app pTemplate="listItem">
      <div class="col-12 md:col-4 mb-3">
        <p-card [header]="'Клуб: ' + app.club" [subheader]="'Тренер: ' + app.trainer" styleClass="p-card-shadow mr-3 h-full">
          <ng-template pTemplate="header">
            <div class="bg-green-300 w-full h-1rem"></div>
          </ng-template>
          <div class="mb-1">Представитель: {{ app.predstavitel }}</div>
          <div class="mb-1">Кол-во спортсменов: {{ app.sportsmens.length }}</div>
          <ng-template pTemplate="footer">
            <div class="flex justify-content-start">
              <button pButton pRipple type="button" label="Отклонить" icon="pi pi-check" class="w-6 p-button-outlined p-button-danger" (click)="onReject(app['id'])"></button>
            </div>
          </ng-template>
        </p-card>
      </div>
    </ng-template>
  </p-dataView>

  <h4>Отклоненные ({{ rejected.length }})</h4>
  <p-dataView [value]="rejected" [paginator]="true" [rows]="6" emptyMessage="Отклоненных заявок нет">
    <ng-template let-app pTemplate="listItem">
      <div class="col-12 md:col-4 mb-3">
        <p-card [header]="'Клуб: ' + app.club" [subheader]="'Тренер: ' + app.trainer" styleClass="p-card-shadow mr-3 h-full">
          <ng-template pTemplate="header">
            <div class="bg-red-300 w-full h-1rem"></div>
          </ng-template>
          <div class="mb-1">Представитель: {{ app.predstavitel }}</div>
          <div class="mb-1">Кол-во спортсменов: {{ app.sportsmens.length }}</div>
          <ng-template pTemplate="footer">
            <div class="flex justify-content-start">
              <button pButton pRipple type="button" label="Подтвердить" icon="pi pi-check" class="w-6 p-button-outlined p-button-success" (click)="onConfirm(app['id'])"></button>
            </div>
          </ng-template>
        </p-card>
      </div>
    </ng-template>
  </p-dataView>
</div>

<div>
  <app-total-stat [turnirId]="turnirId"></app-total-stat>
</div>

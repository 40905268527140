<div class="border-1 shadow-1 surface-border p-3 border-round mb-1">
  <div class="flex justify-content-between mb-3">
    <div>
      <span class="block text-500 font-medium mb-3">{{ label }}</span>
      <div class="text-900 font-medium text-xl">{{ value }}</div>
    </div>
    <div class="flex align-items-center justify-content-center bg-{{color}}-100 border-round" style="width:2.5rem;height:2.5rem">
      <i class="pi {{ icon }} text-{{color}}-500 text-xl"></i>
    </div>
  </div>
</div>
